import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { TShipmentManagement } from "./state_management/types";
import { connect } from "react-redux";
import { ShipmentState, Dispatcher, RootState, AppState } from "AppTypes";
import { shipmentActions } from "./state_management/actions";
import { appActions } from "@app/state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Modal } from "../../../components/Modal";
import { success, error } from '@services/toast-service'
import "./ShipmentRegistration.css"
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { VALIDATE_NUMBER } from "@const/constants";

type Props = {
    dispatch: Dispatcher;
    shipmentState: ShipmentState;
    appState: AppState
};


const ShipmentRegistration: React.FC<Props> = ({ shipmentState, dispatch, appState }) => {
    const { orderDetailUpdate,
        orderDetailInsert,
        selectedPlannedDate,
        order, locationList,
        mixtureList,
        pumpList,
        factoryList,
        selectedPumpId,
        warningTimeSetting
    } = shipmentState;
    const selectedLocationId = appState.currentLocationId;
    const selectedLocation = locationList.find(x => x.id === selectedLocationId);

    const history = useHistory()
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    useEffect(() => {
        dispatch(shipmentActions.fetchPumpList({}));
        dispatch(shipmentActions.fetchLocationList());
        dispatch(shipmentActions.fetchFactoryList());
        dispatch(shipmentActions.fetchMixtureList());


        
        if (!selectedLocationId) {

            const storedLocationId = localStorage.getItem("currentLocationId") ?? "";
            if (!storedLocationId) {
                history.push("/locationselector")
            } else {
                dispatch(appActions.setCurrentLocationId(storedLocationId));
                dispatch(shipmentActions.fetchWarningTimeSetting(storedLocationId));
                dispatch(shipmentActions.fetchOrder({ plannedDate: selectedPlannedDate, locationId: storedLocationId }));

            }

        } else {
            dispatch(shipmentActions.fetchWarningTimeSetting(selectedLocationId));
            dispatch(shipmentActions.fetchOrder({ plannedDate: selectedPlannedDate, locationId: selectedLocationId }));

        }

       
    }, []);



    useEffect(() => {
        if (selectedLocationId && selectedPlannedDate) {
            dispatch(shipmentActions.fetchOrder({ plannedDate: selectedPlannedDate, locationId: selectedLocationId }));
        }

    }, [selectedLocationId]);

    const isEditableDate = (date: string) => {
        var today = new Date();
        var plannedDate = new Date(date);

        return plannedDate.getDate() >= today.getDate();
    }
    const handleAddOrderDetailClick = async () => {

        const { plannedTransferAmount,
            scheduledShippingStartTime,
            scheduledShippingEndTime,
            factoryId,
            mixtureId,
            loadPerTruck } = orderDetailInsert;

        if (!factoryId) {
            error("出荷工場を選択してください。");
            return;
        }

        if (!mixtureId) {
            error("配合を選択してください。");
            return;
        }

        if (!plannedTransferAmount) {
            error("出荷予定量を入力してください。");
            return;
        }

        if (!scheduledShippingStartTime) {
            error("出荷開始予定時刻を入力してください。");
            return;
        }

        if (!scheduledShippingEndTime) {
            error("出荷終了予定時刻を入力してください。");
            return;
        }

        if (scheduledShippingStartTime >= scheduledShippingEndTime) {
            error("出荷終了予定時刻の入力に誤りがあります。");
            return;
        }

        if (!loadPerTruck) {
            error("車１台の積載量を入力してください。");
            return;
        }


        try {
            const response = await dispatch(shipmentActions.insertOrderDetail({
                ...orderDetailInsert, locationId: selectedLocationId, plannedDate: selectedPlannedDate, plannedVolume: order.plannedVolume,
                scheduledShippingStartTime: moment(scheduledShippingStartTime).format("YYYY-MM-DDTHH:mm:ss"),
                scheduledShippingEndTime: moment(scheduledShippingEndTime).format("YYYY-MM-DDTHH:mm:ss")
            }));
            if (response.payload && response.payload.success) {
                await dispatch(shipmentActions.fetchOrder({ plannedDate: selectedPlannedDate, locationId: selectedLocationId }));
                success("保存が完了しました。");
                await dispatch(shipmentActions.resetOrderDetailInsert({}));

                await dispatch(shipmentActions.fetchOrderByLocation(selectedLocationId));
                await dispatch(shipmentActions.fetchProcessChartData({ locationId: selectedLocationId }));

            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }
    const handleUpdateButtonClick = (id: string) => {
        dispatch(shipmentActions.setUpdatingId(id));
    }

    const handleUpdateCancelButtonClick = (id: string) => {
        dispatch(shipmentActions.setUpdatingId(''));

    }
    const handleDeleteCancelButtonClick = async () => {
        setShowDeleteConfirmModal(false);
        await dispatch(shipmentActions.setDeletingId(''));

    }



    const handleUpdateButtonSaveClick = async (id: string) => {

        const { plannedTransferAmount,
            scheduledShippingStartTime,
            scheduledShippingEndTime,
            factoryId,
            mixtureId,
            loadPerTruck } = orderDetailUpdate;

        if (!factoryId) {
            error("出荷工場を選択してください。");
            return;
        }

        if (!mixtureId) {
            error("配合を選択してください。");
            return;
        }

        if (!plannedTransferAmount) {
            error("出荷予定量を入力してください。");
            return;
        }

        if (!scheduledShippingStartTime) {
            error("出荷開始予定時刻を入力してください。");
            return;
        }

        if (!scheduledShippingEndTime) {
            error("出荷終了予定時刻を入力してください。");
            return;
        }

        if (scheduledShippingStartTime >= scheduledShippingEndTime) {
            error("出荷終了予定時刻の入力に誤りがあります。");
            return;
        }

        if (!loadPerTruck) {
            error("車１台の積載量を入力してください。");
            return;
        }
        try {
            const response = await dispatch(shipmentActions.updateOrderDetail(orderDetailUpdate));
            if (response.payload && response.payload.success) {
                await dispatch(shipmentActions.fetchOrder({ plannedDate: selectedPlannedDate, locationId: selectedLocationId }));
                await dispatch(shipmentActions.resetUpdatingOrderDetail({}));
                success("保存が完了しました。");

            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleDeleteButtonClick = async (id: string) => {
        await dispatch(shipmentActions.setDeletingId(id));
        setShowDeleteConfirmModal(true);
    }

    const handleChangeLocationProperty = (key: string, value: string | number) => {
        dispatch(shipmentActions.setLocationData({ key, value, selectedLocationId }));
    }

    const handleChangeWarningTimeSetting = (key: string, value: string | number) => {
        dispatch(shipmentActions.setWarningTimeSetting({ key, value }));
    }

    const handleChangeOrderData = (key: string, value: string | number) => {
        dispatch(shipmentActions.setOrderData({ key, value }));
    }

    const handleSavePlannedVolume = async () => {
        if (!order.plannedVolume || !order.id) {
            error("計画打設量を入力してください。");
            return;
        }

        const response = await dispatch(shipmentActions.updateOrderPlannedVolume({ id: order.id, plannedVolume: order.plannedVolume }));
        if (response.payload && response.payload.success) {
            await dispatch(shipmentActions.fetchOrder({ plannedDate: selectedPlannedDate, locationId: selectedLocationId }));
            success("保存が完了しました。");
        } else {
            error(response.payload.errors[0])
        }

    }



    const handleChangeUpdatingOrderDetailData = (key: string, value: string | number) => {
        dispatch(shipmentActions.setUpdatingOrderDetailData({ key, value }));
    }

    const handleChangeInsertingOrderDetailData = (key: string, value: string | number) => {
        dispatch(shipmentActions.setInsertingOrderDetailData({ key, value }));
    }

    const handleChangePlannedDate = async (value: string) => {
        if (value && value !== '') {
            await dispatch(shipmentActions.setSelectedPlannedDate(moment(value).format("YYYY-MM-DD")));
            // await dispatch(shipmentActions.fetchOrder({ plannedDate: selectedPlannedDate, locationId: selectedLocationId }));
        }
    }

    const handleChangePlannedDateKeyboard = async (value: string) => {
        if (value !== '') {
            await dispatch(shipmentActions.fetchOrder({ plannedDate: selectedPlannedDate, locationId: selectedLocationId }));
        }
    }

    const handleUpdatePumpClick = async () => {
        if (!selectedLocation || !selectedPumpId) {
            error("無効なデータ");
            return;
        }

        const postedData = { id: selectedLocationId, pumpId: selectedLocation?.pumpId };
        const response = await dispatch(shipmentActions.updateLocationPump(postedData));
        if (response.payload && response.payload.success) {
            success("保存が完了しました。");
        } else {
            error(response.payload.errors[0])
        }
    }


    const handleDeleteOrderDetailConfirmButtonClick = async () => {
        const response = await dispatch(shipmentActions.deleteOrderDetail(shipmentState.deletingOrderDetailId));
        if (response.payload && response.payload.success) {
            await dispatch(shipmentActions.fetchOrder({ plannedDate: selectedPlannedDate, locationId: selectedLocationId }));
            setShowDeleteConfirmModal(false);
            success("保存が完了しました。");
        } else {
            setShowDeleteConfirmModal(false);
            error(response.payload.errors[0])
        }

    }

    const handleUpdateWarningTimeSetting = async () => {

        const { beginDeliveryLimit,
            beginDeliveryWarningTime,
            endDeliveryWarningTime,
            overlapLimitTime,
            overlapLimitWarningTime
        } = warningTimeSetting;

        if (!beginDeliveryLimit || (beginDeliveryLimit && !VALIDATE_NUMBER.test(beginDeliveryLimit.toString()))) {
            error("打設制限時間は数値で入力してください。")
            return;
        }
        if (!beginDeliveryWarningTime || (beginDeliveryWarningTime && !VALIDATE_NUMBER.test(beginDeliveryWarningTime.toString()))) {
            error("荷卸開始警告時間は数値で入力してください。")
            return;
        }
        if (!endDeliveryWarningTime || (endDeliveryWarningTime && !VALIDATE_NUMBER.test(endDeliveryWarningTime.toString()))) {
            error("荷卸終了警告時間は数値で入力してください。")
            return;
        }
        if (!overlapLimitTime || (overlapLimitTime && !VALIDATE_NUMBER.test(overlapLimitTime.toString()))) {
            error("打重ね制限時間は数値で入力してください。")
            return;
        }
        if (!overlapLimitWarningTime || (overlapLimitWarningTime && !VALIDATE_NUMBER.test(overlapLimitWarningTime.toString()))) {
            error("打重ね警告時間は数値で入力してください。")
            return;
        }

        const response = await dispatch(shipmentActions.updateLocationLimitTime({
            id: selectedLocationId,
            unloadTimeLimit: warningTimeSetting?.beginDeliveryLimit,
            beginUnloadWarningTime: warningTimeSetting?.beginDeliveryWarningTime,
            endDeliveryLimit: warningTimeSetting?.beginDeliveryLimit,
            endUnloadWarningTime: warningTimeSetting?.endDeliveryWarningTime,
            overlapLimitTime: warningTimeSetting?.overlapLimitTime,
            overlapLimitWarningTime: warningTimeSetting?.overlapLimitWarningTime
        }));
        if (response.payload && response.payload.success) {
            await dispatch(shipmentActions.fetchOrder({ plannedDate: selectedPlannedDate, locationId: selectedLocationId }));
            success("保存が完了しました。");
        } else {
            error(response.payload.errors[0])
        }

    }
    const isEditable = order.id && selectedPlannedDate && isEditableDate(selectedPlannedDate);

    const renderList = order.details && order.details.length ? (
        order.details.map((orderDetail: TShipmentManagement.OrderDetail) => (
            <tr key={orderDetail.id}>
                <td>
                    {
                        orderDetail.id !== shipmentState.updatingOrderDetailId ?
                            (
                                <div className="wd-100">
                                    <input type="button" name="btnRowEdit" value="編" onClick={() => { handleUpdateButtonClick(orderDetail.id) }} hidden={process.env.REACT_APP_MODE_RUN === 'viewer'} />
                                    <input type="button" name="btnRowDelete" value="削" onClick={() => { handleDeleteButtonClick(orderDetail.id) }} className="ml-3" hidden={process.env.REACT_APP_MODE_RUN === 'viewer'} />
                                </div>
                            )
                            :
                            (
                                <div className="wd-100">
                                    <input type="button" name="btnRowEdit" value="更新" onClick={() => handleUpdateButtonSaveClick(orderDetail.id)} />
                                    <input type="button" name="btnRowDelete" value="キャンセル" onClick={() => handleUpdateCancelButtonClick(orderDetail.id)} className="ml-3" />
                                </div>
                            )
                    }
                </td>
                <td>
                    {
                        orderDetail.id !== shipmentState.updatingOrderDetailId ? orderDetail.factoryName :
                            (
                                <select className="form-select" defaultValue={orderDetailUpdate.factoryId} onBlur={e => handleChangeUpdatingOrderDetailData('factoryId', e.currentTarget.value)}>
                                    {factoryList.map(f => (
                                        <option key={f.id} value={f.id}>{f.name}</option>
                                    ))}
                                </select>

                            )

                    }
                </td>
                <td>
                    {
                        orderDetail.id !== shipmentState.updatingOrderDetailId ? orderDetail.mixtureName :
                            (
                                <select className="form-select wd-150" defaultValue={orderDetailUpdate.mixtureId} onBlur={e => handleChangeUpdatingOrderDetailData('mixtureId', e.currentTarget.value)}>
                                    {mixtureList?.length && mixtureList.map(m => (
                                        <option key={m.id} value={m.id}>{m.name1 ?? ""} {m.name2 ?? ""}</option>
                                    ))}
                                </select>
                            )
                    }
                </td>
                <td>
                    {
                        orderDetail.id !== shipmentState.updatingOrderDetailId ? orderDetail.plannedTransferAmount.toFixed(2) :
                            (<input type="number" required className="form-input" value={orderDetailUpdate.plannedTransferAmount} onChange={(e) => { handleChangeUpdatingOrderDetailData('plannedTransferAmount', e.target.value) }} />)

                    }
                </td>
                <td>
                    {
                        orderDetail.id !== shipmentState.updatingOrderDetailId ? moment(orderDetail.scheduledShippingStartTime).format("YYYY/MM/DD HH:mm") :
                            (

                                <input type="datetime-local" required className="form-control wd-210" value={moment(orderDetailUpdate.scheduledShippingStartTime).format("YYYY-MM-DDTHH:mm")} onChange={(e) => { handleChangeUpdatingOrderDetailData('scheduledShippingStartTime', e.target.value) }} />
                            )

                    }
                </td>
                <td>
                    {
                        orderDetail.id !== shipmentState.updatingOrderDetailId ? moment(orderDetail.scheduledShippingEndTime).format("YYYY/MM/DD HH:mm") :
                            (
                                <input type="datetime-local" required className="form-control wd-210" value={moment(orderDetailUpdate.scheduledShippingEndTime).format("YYYY-MM-DDTHH:mm")} onChange={(e) => { handleChangeUpdatingOrderDetailData('scheduledShippingEndTime', e.target.value) }} />
                            )
                    }

                </td>
                <td>
                    {/*{*/}
                    {/*    orderDetail.id !== shipmentState.updatingOrderDetailId ? orderDetail.transportTime.toFixed(2) :*/}
                    {/*        (*/}
                    {/*            <input type="number" required className="form-input" value={orderDetailUpdate.transportTime || 0} onChange={(e) => { handleChangeUpdatingOrderDetailData('transportTime', parseFloat(e.target.value)) }} />*/}
                    {/*        )*/}
                    {/*}*/}
                    {orderDetail.transportTime}
                </td>

                <td>
                    {
                        orderDetail.id !== shipmentState.updatingOrderDetailId ? orderDetail.loadPerTruck.toFixed(2) :
                            (
                                <input type="number" required className="form-input" value={orderDetailUpdate.loadPerTruck} onChange={(e) => { handleChangeUpdatingOrderDetailData('loadPerTruck', e.target.value) }} />
                            )
                    }

                </td>
                <td>{orderDetail.transferAmount}</td>
            </tr>
        ))
    ) : (
        <></>
    );

    return (
        <>
            <LoadingSpinner isShowing={shipmentState.isLoading} />
            <div className="settingItemPageTitle">
                <p>出荷登録</p>
            </div>
            <div className="row filter-container">
                <div className="col-6">
                    <div className="form-group date-filter">
                        <label className="control-label">出荷予定日</label>
                        <input
                            className="form-control"
                            value={selectedPlannedDate}
                            type="date"
                            style={{ width: 300 }}
                            onChange={e => handleChangePlannedDate(e.target.value)}
                            onBlur={event => handleChangePlannedDateKeyboard(event.target.value)}
                        />

                    </div>
                    <div className="form-group mt-3">
                        <label className="control-label">計画打設量（m3）</label>
                        <div>
                            <input
                                className="form-control d-inline"
                                value={order.plannedVolume}
                                type="number"
                                min={0}
                                style={{ width: 100 }}
                                onChange={e => handleChangeOrderData("plannedVolume", parseFloat(e.target.value))}
                            />
                            <button hidden={!isEditable || process.env.REACT_APP_MODE_RUN === 'viewer'} className="btn btn-primary bg-grey mb-1 ml-3" onClick={() => handleSavePlannedVolume()} >計画打設量変更</button>
                        </div>

                    </div>
                </div>

                <div className="col-6">
                    <table className="table table-bordered timeWarningTable">
                        <tbody>
                            <tr>
                                <td style={{ backgroundColor: '#FF0000' }}>
                                    打設制限時間（分）：
                                </td>
                                <td style={{ backgroundColor: '#FF0000' }}>
                                    <input
                                        className="form-control d-inline"
                                        value={warningTimeSetting.beginDeliveryLimit}
                                        type="text"
                                        style={{ width: 100 }}
                                        onChange={e => { handleChangeWarningTimeSetting("beginDeliveryLimit", e.target.value) }}
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td style={{ backgroundColor: '#FF66FF' }}>
                                    荷卸開始警告時間（分）：
                                </td>
                                <td style={{ backgroundColor: '#FF66FF' }}>
                                    <input
                                        className="form-control d-inline"
                                        value={warningTimeSetting.beginDeliveryWarningTime}
                                        type="text"
                                        style={{ width: 100 }}
                                        onChange={e => { handleChangeWarningTimeSetting("beginDeliveryWarningTime", e.target.value) }}
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td style={{ backgroundColor: '#FF66FF' }}>
                                    荷卸終了警告時間（分）：
                                </td>
                                <td style={{ backgroundColor: '#FF66FF' }}>
                                    <input
                                        className="form-control d-inline"
                                        value={warningTimeSetting.endDeliveryWarningTime}
                                        type="text"
                                        style={{ width: 100 }}
                                        onChange={e => { handleChangeWarningTimeSetting("endDeliveryWarningTime", e.target.value) }}
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td colSpan={2} />
                            </tr>
                            <tr>
                                <td style={{ backgroundColor: '#FF0000' }}>
                                    打重ね制限時間（分）：
                                </td>
                                <td style={{ backgroundColor: '#FF0000' }}>
                                    <input
                                        className="form-control d-inline"
                                        value={warningTimeSetting.overlapLimitTime}
                                        type="text"
                                        style={{ width: 100 }}
                                        onChange={e => { handleChangeWarningTimeSetting("overlapLimitTime", e.target.value) }}
                                    />
                                </td>

                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#FF66FF' }}>
                                    打重ね警告時間（分）：
                                </td>
                                <td style={{ backgroundColor: '#FF66FF' }}>
                                    <input
                                        className="form-control d-inline"
                                        value={warningTimeSetting.overlapLimitWarningTime}
                                        type="text"
                                        style={{ width: 100 }}
                                        onChange={e => { handleChangeWarningTimeSetting("overlapLimitWarningTime", e.target.value) }}
                                    />
                                </td>

                            </tr>
                        </tbody>

                    </table>
                    <button onClick={() => handleUpdateWarningTimeSetting()} className="btn btn-primary bg-grey mb-1 ml-3" hidden={process.env.REACT_APP_MODE_RUN === 'viewer'}>警告時間更新</button>
                </div>

            </div>
            <div className="content shipment-container">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th />
                            <th scope="col" style={{ width: "15%" }}>出荷工場</th>
                            <th scope="col" style={{ width: "15%" }}>配合</th>
                            <th scope="col">出荷予定量（m3）</th>
                            <th scope="col">出荷開始予定時刻</th>
                            <th scope="col">出荷終了予定時刻</th>
                            <th scope="col">出荷間隔（分）</th>
                            <th scope="col">車1台の積載量（m3）</th>
                            <th scope="col">出荷済み量（m3）</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderList}
                        {(!order.details || order.details.length === 0) &&
                            (
                                <tr>
                                    <td />
                                    <td />
                                    <td />
                                    <td>
                                        0
                                    </td>
                                    <td>
                                        0
                                    </td>
                                    <td>
                                        0
                                    </td>
                                    <td>
                                        0
                                    </td>
                                    <td>
                                        0
                                    </td>
                                    <td>
                                        0
                                    </td>
                                </tr>
                            )}
                        {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                        <tr className="insertform">
                            <td>
                                <input type="button" name="btnAdd" value="追加" onClick={handleAddOrderDetailClick} />
                            </td>
                            <td>
                                <select className="form-select wd-150" defaultValue={orderDetailInsert.factoryId} onBlur={e => handleChangeInsertingOrderDetailData('factoryId', e.currentTarget.value)}>
                                    <option value=""></option>
                                    {factoryList?.length && factoryList?.length && factoryList.map(f => (
                                        <option key={f.id} value={f.id} selected={f.id === orderDetailInsert.factoryId}>{f.name}</option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <select className="form-select wd-150" defaultValue={orderDetailInsert.mixtureId} onBlur={e => handleChangeInsertingOrderDetailData('mixtureId', e.currentTarget.value)}>
                                    <option value=""></option>
                                    {mixtureList?.length && mixtureList.map(m => (
                                        <option key={m.id} value={m.id} selected={m.id === orderDetailInsert.mixtureId}>{m.name1 ?? ""} {m.name2 ?? ""}</option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <input type="number" required className="form-control mw-135" value={orderDetailInsert.plannedTransferAmount} onChange={(e) => { handleChangeInsertingOrderDetailData('plannedTransferAmount', e.target.value) }} />
                            </td>
                            <td className="wd-150">

                                <DatePicker selected={orderDetailInsert?.scheduledShippingStartTime !== '' ? new Date(moment(orderDetailInsert.scheduledShippingStartTime).format()) : null}
                                    className="form-control  wd-210"
                                    showTimeSelect
                                    dateFormat="yyyy/MM/dd HH:mm"
                                    onChange={(date: Date) => {
                                        if (date) {
                                            handleChangeInsertingOrderDetailData('scheduledShippingStartTime', date.toISOString());
                                        }
                                    }} />
                            </td>
                            <td>

                                <DatePicker selected={orderDetailInsert?.scheduledShippingEndTime !== '' ? new Date(moment(orderDetailInsert.scheduledShippingEndTime).format()) : null}
                                    className="form-control  wd-210"
                                    showTimeSelect
                                    dateFormat="yyyy/MM/dd HH:mm"
                                    onChange={(date: Date) => {
                                        if (date) {
                                            handleChangeInsertingOrderDetailData('scheduledShippingEndTime', date.toISOString())
                                        }
                                    }} />
                            </td>
                            <td>
                                <p className="wd-150" />
                            </td>
                            <td>
                                <input type="number" required className="form-control mw-135" value={orderDetailInsert.loadPerTruck} onChange={(e) => { handleChangeInsertingOrderDetailData('loadPerTruck', e.target.value) }} />
                            </td>

                            <td>
                                <p className="wd-150" />
                            </td>
                        </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="goHomeButtonContainer">
                <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton" />
            </div>
            <Modal isActive={showDeleteConfirmModal} onClose={() => handleDeleteCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" onClick={handleDeleteOrderDetailConfirmButtonClick} value="削除" />
                    <input type="button" className="ml-3" onClick={() => { handleDeleteCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>
        </>
    );
};

export default connect((state: RootState) => ({
    shipmentState: state.shipment,
    appState: state.app
}))(ShipmentRegistration);
