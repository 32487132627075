import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import {
  OverlappingState,
  Dispatcher,
  RootState,
  DisplayItemSettingState,
  IDisplayItemSetting,
  AppState,
  LocationSelectorState,
  ConstructionState,
  IOverlappingModel,
} from "AppTypes";
import { useHistory, useLocation } from "react-router-dom";
import { overlappingActions } from "@features/setting/overlapping/state_management/actions";
import { constructionActions } from "@features/construction/state_management/actions";
import { STATUS_MESH, WARNING_STATUS } from '../../../const/constants'

import moment from "moment";

type Props = {
  dispatch: Dispatcher;
  overlappingState: OverlappingState;
  displayItemSettingState: DisplayItemSettingState;
  locationSelectorState: LocationSelectorState;
  appState: AppState;
  constructionState: ConstructionState;
};

const ListMesh: React.FC<Props> = ({
  overlappingState,
  displayItemSettingState,
  appState,
  dispatch,
  locationSelectorState,
  constructionState,
}) => {
  const history = useHistory();
  // const [isShowTableMesh, setShowTableMesh] = useState(true)
  const { data } = overlappingState.overlappingList;

  useEffect(() => {
    if (locationSelectorState.locationSelect !== '') {
      dispatch(
        overlappingActions.fetchList({
          LocationId: locationSelectorState.locationSelect,
        })
      );
    }
  }, [locationSelectorState.locationSelect]);

  const renderBgClass = (mesh: any) => {
    let className = "";
    let textStatus = "未打設";
    if (mesh.status === STATUS_MESH[1]) {
      className = "bg-during-casting";
      textStatus = "打設中";
    } else if (mesh.status === STATUS_MESH[2]) {
      className = "bg-end-casting";
      textStatus = "打設終了";
    }
    if (mesh?.warningStatus === WARNING_STATUS[0]) {
      className = "bg-warning-time-exceeded";
      textStatus = "警告時間超過";
    } else if (mesh?.warningStatus === WARNING_STATUS[1]) {
      className = "bg-time-limit-exceeded";
      textStatus = "制限時間超過";
    }
    return { class: className, text: textStatus };
  };

  if (data?.length === 0) {
    return null;
  }

  return (
    <div className="table-responsive content-mesh-construction">
      <div className="bg-warning text-center" style={{ fontWeight: "bold" }}>
        打重ね情報
      </div>
      <Table
        bordered
        size="sm"
        className="text-center table-mesh-construction"
      >
        <thead>
          <tr>
            <th>メッシュ</th>
            <th>状態</th>
            <th>残り</th>
            <th>開始</th>
            <th colSpan={2} className="config-table">終了</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any) => (
            <tr key={item.id} className={renderBgClass(item).class}>
              <td>{item.code}</td>
              <td>{renderBgClass(item).text}</td>
              <td>{item.remainingTime}</td>
              <td>{item.pumpStartTime ? moment(item.pumpStartTime).format('HH:mm') : ''}</td>
              <td>{item.pumpFinishTime ? moment(item.pumpFinishTime).format('HH:mm') : ''}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default connect((state: RootState) => ({
  overlappingState: state.overlapping,
  displayItemSettingState: state.dipslayItemSetting,
  appState: state.app,
  locationSelectorState: state.locationSelector,
  constructionState: state.construction
}))(ListMesh);
