import React, { Suspense, useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import * as THREE from 'three';
import { connect } from "react-redux";
import { LocationSelectorState, AppState, Dispatcher, RootState, MeshSettingState } from "AppTypes";
import { Canvas, useThree } from '@react-three/fiber'
import moment from 'moment'
import { OrbitControls, PerspectiveCamera, useGLTF, useCursor, GizmoHelper, GizmoViewcube, Stage } from '@react-three/drei'
import { proxy, useSnapshot } from 'valtio'
import { useHistory } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { args, cameraControlOptions, Loader, state, modes } from '../setting/3deditor/ThreeEditor';
import { meshSettingActions } from "@features/setting/meshcolor/state_management/actions";
import { CameraControls } from '@features/setting/3deditor/CameraControl';
import { locationSelectorActions } from "@features/location/selector/state_management/actions";
import { STATUS_MESH, WARNING_STATUS } from '../../const/constants'

type Props = {
  dispatch: Dispatcher;
  locationSelectorState: LocationSelectorState;
  appState: AppState;
  meshSettingState: MeshSettingState;
};

const renderStepTime = (step: number) => {
  let stepValue = 1;
  if (step === 2) {
    stepValue = 2;
  } else if (step === 3) {
    stepValue = 10;
  } else if (step === 4) {
    stepValue = 25
  } else if (step === 5) {
    stepValue = 50
  } else if (step === 6) {
    stepValue = 100
  }
  return stepValue;
}

export const CustomControls = forwardRef((_, ref) => {
  const {scene, camera} = useThree();
  const [initScene, setScene] = useState<any>();
  useEffect(() => {
    const backup = scene.clone(false);
    setScene(backup);
  }, [])

  useImperativeHandle(ref, () => (
    {
      zoomIn() {
        camera.zoom = (camera.zoom + cameraControlOptions.zoomStep) > cameraControlOptions.zoomMax ? cameraControlOptions.zoomMax : (camera.zoom + cameraControlOptions.zoomStep);
        camera.updateProjectionMatrix();
      },
      zoomOut() {
        camera.zoom = (camera.zoom - cameraControlOptions.zoomStep) < cameraControlOptions.zoomMin ? cameraControlOptions.zoomMin : (camera.zoom - cameraControlOptions.zoomStep);
        camera.updateProjectionMatrix();
      },
      reset() {
        camera.zoom = cameraControlOptions.defaultOptions.zoom;
        camera.updateProjectionMatrix();
        const [rX, rY, rZ] = cameraControlOptions.defaultOptions.rotation;
        scene.rotation.set(rX, rY, rZ);
        scene.updateMatrix();
      }
    }
  ))
  return <></>;
})

function Model({ item, setBlockActive, index, meshSettingState, isHidden, isModeSlim }: any) {
  // Ties this component to the state model
  const snap = useSnapshot(state)
  const meshRef = useRef<THREE.Mesh>(null!)
  // Fetching the GLTF, nodes is a collection of all the meshes
  // It's cached/memoized, it only gets loaded and parsed
  const { nodes } = useGLTF(`${process.env.REACT_APP_GLB_ENDPOINT}${state.fileGLB}`)
  // Feed hover state into useCursor, which sets document.body.style.cursor to pointer|auto
  const [hovered, setHovered] = useState(false)
  useCursor(hovered)

  let itemMesh: any = null;
  let opacityMesh = 1;
  for (const [key, value] of Object.entries(nodes)) {
      if (value?.parent?.name === `Block${item.code}` || value?.parent?.name === `${item.code}`) {
          let color = '#FFFAFA';
          opacityMesh = 0.8;
          // find color with setting system
          if (item.warningStatus !== '') {
              const findValueWarning = meshSettingState?.meshSettingList?.find((itemSetting: any) => itemSetting.code === item.warningStatus);
              if (findValueWarning) {
                  opacityMesh = 1 - (findValueWarning.transferTime / 100);
                  if (findValueWarning.code === WARNING_STATUS[0]) {
                      color = '#f766ff';      //pink(warning)
                  } else if (findValueWarning.code === WARNING_STATUS[1]) {
                      color = '#F50505';      //red (alert)
                  }
              }
          } else {
              const findValueStatus = meshSettingState?.meshSettingList?.find((itemSetting: any) => itemSetting.code === item.status);
              if (findValueStatus) {
                  opacityMesh = 1 - (findValueStatus.transferTime / 100);
                  if (findValueStatus.code === STATUS_MESH[0]) {
                      color = '#FFFAFA';      //未打設
                  } else if (!item.isSlim && findValueStatus.code === STATUS_MESH[1]) {
                      color = '#FFFF00';      //打設中
                  } else if (!item.isSlim && findValueStatus.code === STATUS_MESH[2]) {
                      color = '#00B400';      //打設済み
                  } else if (item.isSlim && findValueStatus.code === STATUS_MESH[1]) {
                      color = '#fdfc06';      //薄層打設中
                  } else if (item.isSlim && findValueStatus.code === STATUS_MESH[2]) {
                      color = '#2670c1';      //薄層打設済
                  }
              }
          }

          itemMesh = {
              ...item,
              name: value.name,
              color: color,
              indexMesh: index
          }
      }
  }

  if (itemMesh.name === '' || itemMesh.name === undefined) {
      return null;
  }

  return (
      <mesh
          castShadow
          receiveShadow
          ref={meshRef}
          // Click sets the mesh as the new target
          onClick={(e) => {
              e.stopPropagation();
              state.current = itemMesh.name;
              setBlockActive(itemMesh.indexMesh);
              meshRef.current?.rotation?.set(0, 0, 0)
          }}
          // If a click happened but this mesh wasn't hit we null out the target,
          // This works because missed pointers fire before the actual hits
          onPointerMissed={(e) => {
              if (e.type === 'click') {
                  state.current = null;
                  setBlockActive(null);
              }
          }}
          // Right click cycles through the transform modes
          onContextMenu={(e) => snap.current === itemMesh.name && (e.stopPropagation(), (state.mode = (snap.mode + 1) % modes.length))}
          onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
          onPointerOut={(e) => setHovered(false)}
          name={itemMesh.name}
          /* @ts-ignore */
          geometry={nodes[itemMesh.name].geometry}
          /* @ts-ignore */
          material={nodes[itemMesh.name].material}
          /* @ts-ignore */
          // material-color={snap.current === itemMesh.name ? '#ff6080' : itemMesh.color}
          position={itemMesh.isFloorSelect ? new THREE.Vector3(nodes[itemMesh.name].position.x, nodes[itemMesh.name].position.y + spaceMesh(nodes[itemMesh.name].position.y), nodes[itemMesh.name].position.z) : nodes[itemMesh.name].position}
          material-transparent={true}
          // material-opacity={0.8}
          dispose={null}
          rotation={nodes[itemMesh.name].rotation}
          material-precision={'highp'}
          visible={!isHidden}
          isMesh
      >
          <meshStandardMaterial
              color={snap.current === itemMesh.name ? '#ff6080' : itemMesh.color}
              opacity={opacityMesh}
              transparent={true}
              visible={!isHidden}
          />
      </mesh>
  )
}

const Controls = () => {
  // Get notified on changes to state
  const snap = useSnapshot(state)
  const scene = useThree((state) => state.scene)

  return (
      <>
          {/* As of drei@7.13 transform-controls can refer to the target by children, or the object prop */}
          {/* {snap.current && <TransformControls object={scene.getObjectByName(snap.current)} mode={modes[snap.mode]} />} */}
          <OrbitControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 1.75} rotation={scene.rotation} reverseOrbit={false} />
      </>
  )
}

export const ModelGroup = ({ locationSelectorState, setBlockActive, customControlRef, meshSettingState }: any) => {
  return (
      <group>
          {
              locationSelectorState.locationMesh
              && locationSelectorState.locationMesh[0]?.glbFilePath
              && locationSelectorState.locationMesh[0].meshes?.map((meshItem: any, index: number) => (
                  <Model
                      item={meshItem}
                      key={meshItem.id}
                      setBlockActive={(indexMesh: number) => setBlockActive(indexMesh)}
                      index={index}
                      meshSettingState={meshSettingState}
                      isHidden={meshItem.isHidden}
                      isModeSlim={locationSelectorState.isModeSlim}
                  />
              ))
          }
      </group>
  )
}


const PlayHistory: React.FC<Props> = ({ locationSelectorState, appState, dispatch, meshSettingState }) => {
  const [getFirst, setGetFirst] = useState(false)
  const [listFloors, setListFloors] = useState<any[]>([]);
  const [isGetListFloor, setGetListFloor] = useState(false);
  const [isGetListFloorHistory, setGetListFloorHistory] = useState(false);
  const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || '';
  const customControlRef: any = useRef();
  const [blockActive, setBlockActive] = useState<number>()
  const history = useHistory();
  const [buttonActive, setButtonActive] = useState(2);
  const [buttonActiveType, setButtonActiveType] = useState(1);
  const [buttonActiveExpect, setButtonExpect] = useState(0)
  const [timePlay, setTimePlay] = useState(0);
  const [stepNext, setStepNext] = useState(1);
  const [maxLengthTimePlay, setMaxLengthTimePlay] = useState(1);
  const cameraControlRef = useRef<CameraControls | null>(null)
  const virtualCamera = useRef<CameraControls['camera']>()
  const ref: any = useRef();
  const [isShowModel, setShowModel] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (!locationId) {
      history.push('/locationselector');
    } else {
      dispatch(locationSelectorActions.fetchLocationMeshHistory(locationId));
    }
}, [appState.currentLocationId]);

  useEffect(() => {
    dispatch(meshSettingActions.fetch());
    if (locationId !== '' && !getFirst) {
      setGetFirst(true);
      dispatch(locationSelectorActions.fetchLocationMesh({ locationId: locationId, ignoreFilter: false }));
      dispatch(locationSelectorActions.changeIsGetMeshPlayHistory(true));
    }
    if (locationSelectorState.locationMesh
      && locationSelectorState.locationMesh[0]?.meshes?.length > 0
      && !isGetListFloor) {
        const dataFloors: any[] = []
        locationSelectorState.locationMesh[0]?.meshes?.forEach((item: any) => {
            const findFloor = dataFloors.find(itemFloor => itemFloor.layer === item.layer)
            if (findFloor === undefined) {
                dataFloors.push({
                  layer: item.layer,
                  codeFloor: item.code?.substr(0, 5)
                })
            }
        })
        dataFloors.sort((itemBefore, itemAfter) => itemAfter.layer - itemBefore.layer)
        const dataSetFloors = dataFloors.filter(floor => floor.layer < dataFloors.length)
        setListFloors(dataSetFloors)
        setGetListFloor(true)
    }
    if (locationSelectorState.locationMeshHistory
      && locationSelectorState.locationMeshHistory?.meshHistories?.length > 0
      && !isGetListFloorHistory) {
        // Find time total run
        /*
        const firstTime = moment(locationSelectorState.locationMeshHistory.meshHistories[0].logTime);
        const lastTime = moment(locationSelectorState.locationMeshHistory.meshHistories[locationSelectorState.locationMeshHistory.meshHistories.length - 1].logTime);
        const maxLengthTimePlay = lastTime.diff(firstTime, 'seconds', true);
        setMaxLengthTimePlay(maxLengthTimePlay);
        setGetListFloorHistory(true)
        */
       //Test
        const firstTime = moment(locationSelectorState.locationMeshHistory.meshHistories[0].logTime).subtract(1, 'seconds');
        const lastTime = moment(locationSelectorState.locationMeshHistory.meshHistories[locationSelectorState.locationMeshHistory.meshHistories.length - 1].logTime).add(1, 'seconds');
        const maxLengthTimePlay = lastTime.diff(firstTime, 'seconds');
        setMaxLengthTimePlay(maxLengthTimePlay);
        setGetListFloorHistory(true)
    }
    const interval = setInterval(() => {
      if (buttonActiveType === 2) {
        if (buttonActive !== 2) {
          const firstTime = moment(locationSelectorState.locationMeshHistory.meshHistories[0].logTime);
          if (buttonActive === 1) {
            if (locationSelectorState.stepIndexHistoryPlay - renderStepTime(stepNext) <= 0 && locationSelectorState.stepIndexHistoryPlay !== 0) {
              setButtonActive(2);
              dispatch(locationSelectorActions.changeStepIndexHistoryFirst());
              setTimePlay(0)
            }
            if (locationSelectorState.stepIndexHistoryPlay - renderStepTime(stepNext) > 0) {
              dispatch(locationSelectorActions.changeStepIndexHistory(renderStepTime(stepNext) * -1));
              if (locationSelectorState.locationMeshHistory.meshHistories[locationSelectorState.stepIndexHistoryPlay - renderStepTime(stepNext)]?.logTime) {
                const nowTime = moment(locationSelectorState.locationMeshHistory.meshHistories[locationSelectorState.stepIndexHistoryPlay - renderStepTime(stepNext)]?.logTime);
                const timePlayNow = nowTime.diff(firstTime, 'seconds');
                setTimePlay(timePlayNow);
              }
            }
          } else if (buttonActive === 3) {
            if (locationSelectorState.stepIndexHistoryPlay + renderStepTime(stepNext) > (locationSelectorState.locationMeshHistory.meshHistories.length)) {
              //setTimePlay(maxLengthTimePlay);
              //setButtonActive(2);
              
              dispatch(locationSelectorActions.changeStepIndexHistoryLast(locationSelectorState.locationMeshHistory.meshHistories.length));
              setTimePlay(maxLengthTimePlay);
              setButtonActive(2);
            } else {
              dispatch(locationSelectorActions.changeStepIndexHistory(renderStepTime(stepNext)));
              const nowTime = moment(locationSelectorState.locationMeshHistory.meshHistories[locationSelectorState.stepIndexHistoryPlay + renderStepTime(stepNext)].logTime);
              const timePlayNow = nowTime.diff(firstTime, 'seconds');
              setTimePlay(timePlayNow);
            }
          }
        }
      } else {
        if (buttonActive !== 2 && timePlay > -1) {
          let timePlayNextStep = timePlay;
          if (buttonActive === 1) {
            if (timePlay - renderStepTime(stepNext) < 0) {
              setButtonActive(2)
              dispatch(locationSelectorActions.changeStepIndexHistoryFirst());
            }
            setTimePlay(() => timePlay - renderStepTime(stepNext) < 0 ? 0 : timePlay - renderStepTime(stepNext))
            timePlayNextStep = timePlay - renderStepTime(stepNext) < 0 ? 0 : timePlay - renderStepTime(stepNext)
            if (timePlayNextStep >= 3) {
              dispatch(locationSelectorActions.changeMeshHistory({time: timePlayNextStep}))
            } else {
              dispatch(locationSelectorActions.changeStepIndexHistoryFirst())
            }
          } else if (buttonActive === 3) {
            setTimePlay(() => (timePlay + renderStepTime(stepNext)) < maxLengthTimePlay ? timePlay + renderStepTime(stepNext) : maxLengthTimePlay)
            timePlayNextStep = timePlay + renderStepTime(stepNext) < maxLengthTimePlay ? timePlay + renderStepTime(stepNext) : maxLengthTimePlay
            if (timePlay + renderStepTime(stepNext) > maxLengthTimePlay) setButtonActive(2)
            if (timePlayNextStep >= 3) {
              dispatch(locationSelectorActions.changeMeshHistory({time: timePlayNextStep}))
            } else {
              dispatch(locationSelectorActions.changeStepIndexHistoryFirst())
            }
          }
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [locationId, timePlay, buttonActive, locationSelectorState.locationMesh, locationSelectorState.stepIndexHistoryPlay]);

  useEffect(
    () => {
      const timer = setTimeout(() => {
        setShowModel(false);
      }, 150);
      return () => clearTimeout(timer);
    },
    [isShowModel]
  );

  // const setNextStep = useCallback(() => {
  //   if (buttonActive === 1) {
  //     setButtonActive(3)
  //     // setStepNext(() => 1)
  //   } else {
  //     setStepNext(() => stepNext === 5 ? 1 : stepNext + 1)
  //   }
  // }, [stepNext, setStepNext, buttonActive]);

  // const setPreviousStep = useCallback(() => {
  //   if (buttonActive === 3) {
  //     setButtonActive(1)
  //     // setStepNext(1)
  //   } else {
  //     setStepNext(() => stepNext === 5 ? 1 : stepNext + 1)
  //   }
  // }, [stepNext, setStepNext, buttonActive]);

  const reset = () => {
    customControlRef.current?.reset();
    setShowModel(true);
  }

  if (locationSelectorState.locationMesh?.length === 0 && locationSelectorState.isLoading) {
    return <div className="d-flex justify-content-center align-items-center">Loading...</div>;
  }

  if (locationSelectorState.locationMesh?.length > 0 ) {
    state.fileGLB = locationSelectorState.locationMesh[0].glbFilePath
  }

  const setActiveMesh = (index: number) => {
    if (index === blockActive) {
      setBlockActive(-1);
      dispatch(locationSelectorActions.resetShowMesh())
    } else {
      setBlockActive(index);
      dispatch(locationSelectorActions.setShowMeshWithFloor({
        floorCode: listFloors[index].codeFloor,
        layer: listFloors[index].layer,
      }));
    }
  }

  const renderBar = () => {
    if (buttonActiveType === 1) {
      {/* @ts-ignore */}
      return <div className="progress-bar" role="progressbar" aria-hidden="true" style={{ width: `${Math.floor((timePlay / maxLengthTimePlay) * 100)}%` }} aria-valuenow={Math.floor((timePlay / maxLengthTimePlay) * 100)} aria-valuemin="0" aria-valuemax="100" />
    } else {
      {/* @ts-ignore */}
      return <div
        className="progress-bar"
        role="progressbar"
        aria-hidden="true"
        style={{ width: `${Math.floor((locationSelectorState.stepIndexHistoryPlay / (locationSelectorState.locationMeshHistory.meshHistories.length - 1)) * 100)}%` }}
        aria-valuenow={Math.floor((locationSelectorState.stepIndexHistoryPlay  / (locationSelectorState.locationMeshHistory.meshHistories.length -1)) * 100)} 
        aria-valuemin="0"
        aria-valuemax="100" />
    }
  }

  const toHoursAndMinutes = (totalSeconds: number) => {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds.toFixed(0)}`
  }

  return (
    <div className="col-12 p-0 view-3d-full">
      <div className="bg-warning text-center" style={{ fontWeight: "bold" }}>打重ね再生</div>
      <div className="three-3d-editor-container three-3d-editor-container-history">
        <Canvas className="view-3d-block">
          <pointLight position={[10, 10, 10]} intensity={0.8} />
          <hemisphereLight color="#A6A7A5" groundColor="#696B67" position={cameraControlOptions.defaultOptions.position} intensity={0.85} />
            <Suspense fallback={<Loader />}>
              <Stage controls={ref} contactShadow={{ blur: 1, opacity: 0, position: [0, 0, 0] }} intensity={1} environment={null} preset="rembrandt">
                <group dispose={null}>
                  {!isShowModel && (
                    <ModelGroup locationSelectorState={locationSelectorState} setBlockActive={setBlockActive} customControlRef={customControlRef} meshSettingState={meshSettingState} />
                  )}
                </group>
              </Stage>
              <GizmoHelper alignment="bottom-right" margin={[args.marginX, args.marginY]}>
                <GizmoViewcube
                  {...args}
                  onClick={() => null}
                />
              </GizmoHelper>
            </Suspense>
          <CameraControls ref={cameraControlRef} camera={virtualCamera.current} />
        </Canvas>
         {/* <div className="three-3d-list-mesh">
          <div className="list-mesh">
            {locationSelectorState.locationMesh[0]?.glbFilePath && locationSelectorState.locationMesh[0]?.meshes.length > 0
              && listFloors.map((floor, index) => (
                <div key={index} className={index === blockActive ? `active-mesh` : ''} aria-hidden="true" onClick={() => setActiveMesh(index)}>{floor.layer}</div>
              ))
            }
          </div>
        </div> */}
        <div className="three-3d-editor-panel">
          <div className="controls">
            <div className="control">
              <div aria-hidden="true" className="default-action" onClick={() => {
                reset();
              }}>
                リセット
              </div>
            </div>
            <div className="control">
              <div className="zoom-actions">
                <div className="action in" onClick={() => {
                  customControlRef.current?.zoomIn();
                }}>
                  <i className="fa fa-plus"></i>
                </div>
                <div className="action out" onClick={() => {
                  customControlRef.current?.zoomOut();
                }}>
                  <i className="fa fa-minus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="progress-play">
        <div className="progress">
          {renderBar()}
        </div>
      </div>

      <div className="control-play d-flex d-flex-row">
        <div className="time-play-history-show d-flex align-items-center">
          {buttonActiveType !== 1
            ? (<>{locationSelectorState.stepIndexHistoryPlay} / {locationSelectorState.locationMeshHistory.meshHistories.length} step</>)
            : (<>{toHoursAndMinutes(timePlay)} / {toHoursAndMinutes(maxLengthTimePlay)}</>)}
        </div>
        <div className={`list-button-click d-flex just-content-center ${buttonActiveType !== 1 ? 'list-button-click-extend' : ''}`}>
          <div className="list-button-play">
            <div
              className="button-stop-all title-selector"
              aria-hidden="true"
              onClick={() => {
                setTimePlay(0);
                setButtonActive(2);
                setButtonExpect(0)
                dispatch(locationSelectorActions.changeStepIndexHistoryFirst())
              }}
            >
              <i className="fas fa-stop"></i>
            </div>
            {/* <div
              className={buttonActiveExpect === 1 ? 'active-button-play title-selector' : 'title-selector'}
              aria-hidden="true"
              onClick={() => {
                // setStepNext(1)
                setButtonActive(1)
                setButtonExpect(1)
              }}
            >
              <i className="fas fa-backward"></i>
            </div> */}
            <div
              className={buttonActive === 1 && buttonActiveExpect === 0 ? 'active-button-play title-selector' : 'title-selector'}
              aria-hidden="true"
              onClick={() => {
                // setStepNext(1)
                setButtonActive(1)
                setButtonExpect(0)
              }}
            >
              <i className="fas fa-caret-left"></i>
            </div>
            <div
              className={buttonActive === 2 && buttonActiveExpect === 0 ? 'active-button-play title-selector' : 'title-selector'}
              aria-hidden="true"
              onClick={() => {
                setButtonActive(2);
                setButtonExpect(0);
              }}
            >
              <i className="fas fa-pause"></i>
            </div>
            <div
              className={buttonActive === 3 && buttonActiveExpect === 0 ? 'active-button-play title-selector' : 'title-selector'}
              aria-hidden="true"
              onClick={() => {
                // setStepNext(1)
                setButtonActive(3)
                setButtonExpect(0)
              }}
            >
              <i className="fas fa-caret-right"></i>
            </div>
            <div
                className={buttonActive === 4 && buttonActiveExpect === 0 ? 'active-button-play title-selector' : 'title-selector'}
                aria-hidden="true"
                onClick={() => {
                    setButtonActive(2)
                    setButtonExpect(0)
                    dispatch(locationSelectorActions.changeStepIndexHistoryLast(locationSelectorState.locationMeshHistory.meshHistories.length))
                    setTimePlay(maxLengthTimePlay)
                }}
            >
                <i className="fas fa-step-forward"></i>
            </div> 
            {/* <div
              className={buttonActiveExpect === 2 ? 'active-button-play title-selector' : 'title-selector'}
              aria-hidden="true"
              onClick={() => {
                // setStepNext(1)
                setButtonActive(3)
                setButtonExpect(2)
              }}
            >
              <i className="fas fa-forward"></i>
            </div> */}
            {/* <div
              className="button-to-end title-selector"
              aria-hidden="true"
              onClick={() => {
                setTimePlay(maxLengthTimePlay);
                setButtonActive(2);
                setButtonExpect(3);
                dispatch(locationSelectorActions.changeStepIndexHistory(locationSelectorState.locationMeshHistory.meshHistories.length))
              }}
            >
              <i className="fas fa-fast-forward"></i>
            </div> */}
          </div>
        </div>
        <div className="step-play">
          <div
            className="button-step"
            // aria-hidden="true"
            // onClick={() => setStepNext(() => stepNext === 5 ? 1 : stepNext + 1)}
          >
            {/* <i className="fas fa-forward"></i> <span className="text-white">X{renderStepTime(stepNext)}</span> */}
            <Dropdown
              isOpen={dropdownOpen}
              toggle={() => setDropdownOpen((prevState) => !prevState)}
            >
              <DropdownToggle>
                X{renderStepTime(stepNext)}
              </DropdownToggle>
              <DropdownMenu container="body">
                <DropdownItem onClick={() => {
                  setStepNext(1)
                }}>
                  X1
                </DropdownItem>
                <DropdownItem onClick={() => {
                  setStepNext(2)
                }}>
                  X2
                </DropdownItem>
                <DropdownItem onClick={() => {
                  setStepNext(3)
                }}>
                  X10
                </DropdownItem>
                <DropdownItem onClick={() => {
                  setStepNext(4)
                }}>
                  X25
                </DropdownItem>
                <DropdownItem onClick={() => {
                  setStepNext(5)
                }}>
                  X50
                </DropdownItem>
                <DropdownItem onClick={() => {
                  setStepNext(6)
                }}>
                  X100
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="list-button-show">
          <button
            className={buttonActive !== 2 || buttonActiveExpect !== 0 ? "btn btn-secondary" : "btn btn-show-type-history"}
            onClick={() => {
              setButtonActiveType(buttonActiveType === 1 ? 2 : 1);
              // setStepNext(1);
            }}
            disabled={buttonActive !== 2 || buttonActiveExpect !== 0}
          >
            {buttonActiveType !== 1 ? '1秒間隔' : '実時間'}
          </button>
        </div>
      </div>
      
      <div className="goHomeButtonContainer">
        <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton ml-5" />
      </div>
      
    </div>
  )
}

export default connect((state: RootState) => ({
  locationSelectorState: state.locationSelector,
  appState: state.app,
  meshSettingState: state.meshSetting,
}))(PlayHistory);
