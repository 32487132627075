import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup'

import { RootState, Dispatcher, LocationSelectorState, ShippingInformationState, ConstructionState, IPump, MeshSettingState, IMeshSetting } from "AppTypes";
import { locationSelectorActions } from "@features/location/selector/state_management/actions";
import { shippingInformationActions } from "@features/shippinginformation/state_management/actions";
import { constructionActions } from "@features/construction/state_management/actions";
import { overlappingActions } from "@features/setting/overlapping/state_management/actions";
import { success, error } from '@services/toast-service';
import Button from 'react-bootstrap/Button';
import { api } from "@services";
import { useQuery } from '@utils/func'
import ThreeEditor from "./components/ThreeEditor";
import ListMesh from "./components/ListMesh";
import LoadingSpinner from '../../components/LoadingSpinner'
import Mode2dEditor from "./components/Mode2dEditor";
import { STATUS_MESH } from '../../const/constants';
import { authActions } from '../auth/state_management/actions';
import ShippingInformationList from "./components/ShippingInformationList";
import './Construction.css'
import { meshSettingActions } from "@features/setting/meshcolor/state_management/actions";

type Props = {
    dispatch: Dispatcher;
    locationSelectorState: LocationSelectorState;
    shippingInformationState: ShippingInformationState;
    constructionState: ConstructionState;
    meshSettingState: MeshSettingState;
};

const meshSettingValidationSchema = Yup.object().shape({
    data: Yup.array().of(
        Yup.object().shape({
            transferTime: Yup.number()
                .integer("整数で入力してください")
                .min(0, '透過率は0～100の数値で入力してください')
                .max(100, '透過率は0～100の数値で入力してください')
                .required('透過率を入力してください'),
        })
    )
  })

interface IDataMeshSetting {
    data: IMeshSetting[],
}

const ConstructionPage: React.FC<Props> = ({
    dispatch,
    locationSelectorState,
    shippingInformationState,
    constructionState,
}) => {
    const [show, setShow] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [showModalStartStatus, setShowModalStartStatus] = useState(false)
    const [showModalSuccessStatus, setShowModalSuccessStatus] = useState(false)
    const [showMode, changeShowMode] = useState(1) // 1: 3d, 2: 2d
    const query = useQuery();
    const locationSelectQuery = query.get('locationSelect')
    const pumpSelectQuery = query.get('pumpSelect')
    const mode3d = query.get('mode3d')
    const modeShowFloor = query.get('modeShowFloor')
    const floorActive = query.get('floorActive')
    const isShowMeshNotComplete = query.get('isShowMeshNotComplete');
    const [dataMeshSetting, setDataMeshSetting] = useState<IDataMeshSetting>({
        data: [],
    });
    const [isShowModal, setShowModal] = useState(false);
    const [isFirstLoadParam, setFirstLoadFarm] = useState(false);

    const getMeshFirst = useCallback(async () => {
        if (locationSelectQuery && !isFirstLoadParam) {
            dispatch(locationSelectorActions.changeLocationSelect(locationSelectQuery));
            // const filterState = {...locationSelectorState}
            if (mode3d) {
                changeShowMode(mode3d === 'true' ? 1 : 2);
                dispatch(locationSelectorActions.changeModeShow3d(mode3d === 'true'));
            }
            if (isShowMeshNotComplete) {
               dispatch(locationSelectorActions.changeShowMeshNotComplete(isShowMeshNotComplete === 'true'))
            }
            if (modeShowFloor) {
                dispatch(locationSelectorActions.setModeShowFloor(modeShowFloor === 'true' ? 2 : 1));
            }
            if (floorActive) {
               dispatch(locationSelectorActions.changeFloorActive(Number(floorActive)));
            }
        }
        const res = await dispatch(locationSelectorActions.fetchLocationAll());
        if (res.meta?.requestStatus?.toLocaleLowerCase() === 'fulfilled') {
            let resPump;
            if (locationSelectQuery && !isFirstLoadParam) {
                resPump = await dispatch(shippingInformationActions.fetchPumpList({ LocationId: locationSelectQuery }));
            } else {
                resPump = await dispatch(shippingInformationActions.fetchPumpList({ LocationId: res.payload.data[0]?.id }));
            }


            if (resPump?.meta?.requestStatus?.toLocaleLowerCase() === 'fulfilled') {
                const pumpList: IPump[] = resPump?.payload || [];
                let pumpSelectItem = pumpList[0].id;
                if (pumpSelectQuery && !isFirstLoadParam) {
                    dispatch(locationSelectorActions.changePumpSelect(pumpSelectQuery));
                    pumpSelectItem = pumpSelectQuery;
                } else {
                    dispatch(locationSelectorActions.changePumpSelect(pumpList[0].id));
                }
                // get pump is selected with information mode slim
                const pumpItem = pumpList.find(item => item.id === pumpSelectItem);
                if (pumpItem) {
                    dispatch(locationSelectorActions.setModeSlim(pumpItem.isSlim));
                }
            }
        }
        setFirstLoadFarm(true);
        return true;
    }, [])

    useEffect(() => {
        if (locationSelectorState.locationListSuccess?.length === 0) {
            getMeshFirst()
        }
    }, []);

    useEffect(
        () => {
            const timer1 = setTimeout(() => {
                if (locationSelectorState.locationListSuccess?.length === 0) {
                    getMeshFirst()
                }
            }, 2000)
            return () => {
                clearTimeout(timer1);
            };
        },
        [locationSelectorState.locationListSuccess]
      );

    const getInfoMeshSetting = async () => {
        try {
            const dataMeshsetting = await api.meshSettingAPI.fetch();
            if (dataMeshsetting.data.data) {
                setDataMeshSetting({ data: dataMeshsetting.data.data });
            }
        } catch (error) {
            console.log('getInfoMeshSetting: ', error)
        }
    }

    useEffect(() => {
        const getSettingLocal = localStorage.getItem('meshSetting');
        if (getSettingLocal) {
            const dataMeshSettingLocal = JSON.parse(getSettingLocal);
            setDataMeshSetting({ data: dataMeshSettingLocal });
            dispatch(meshSettingActions.setMeshTransferTimeAll(dataMeshSettingLocal));
        } else {
            getInfoMeshSetting();
        }
    }, [])

    const revertByPump = async () => {
        try {
            if (locationSelectorState.pumpSelect) {
                const response = await dispatch(constructionActions.revertByPump(locationSelectorState.pumpSelect));
                if (response.payload && response.payload?.success) {
                    success('すべての打設中メッシュを一括で取り消しました。');
                    setShowModalDelete(false);
                    dispatch(locationSelectorActions.fetchLocationMesh({ locationId: locationSelectorState.locationSelect, igoreFilter: true }));
                } else {
                    error(response?.payload?.errors[0]);
                }
            }
        } catch (err) {
            error('システムに問題が発生しました。');
        }
    }

    const callApiStartStatus = async () => {
        try {
            const dataSend = {
                id: locationSelectorState.locationSelect,
                status: STATUS_MESH[1]
            }
            const response = await dispatch(constructionActions.fetchLocationUpdateStatus(dataSend));
            if (response.payload && response.payload?.success) {
                success('作業中に変更しました。');
                setShowModalStartStatus(false);
                dispatch(locationSelectorActions.fetchLocationMesh({ locationId: locationSelectorState.locationSelect, igoreFilter: true }));
                await dispatch(locationSelectorActions.fetchLocationAll());
                await dispatch(
                    overlappingActions.fetchList({
                        LocationId: locationSelectorState.locationSelect,
                    })
                );
                await dispatch(locationSelectorActions.changeShowMeshNotComplete(locationSelectorState.filter.isShowMeshNotComplete))
            } else {
                error(response?.payload?.errors[0]);
            }
        } catch (err) {
            error('システムに問題が発生しました。');
        }
    }

    const callApiSuccessStatus = async () => {
        try {
            const dataSend = {
                id: locationSelectorState.locationSelect,
                status: STATUS_MESH[2]
            }
            const response = await dispatch(constructionActions.fetchLocationUpdateStatus(dataSend));
            if (response.payload && response.payload?.success) {
                success('作業終了に変更しました。');
                setShowModalSuccessStatus(false)
                dispatch(locationSelectorActions.fetchLocationMesh({ locationId: locationSelectorState.locationSelect, igoreFilter: true }));
                await dispatch(locationSelectorActions.fetchLocationAll());
                await dispatch(
                    overlappingActions.fetchList({
                        LocationId: locationSelectorState.locationSelect,
                    })
                );
                await dispatch(locationSelectorActions.changeShowMeshNotComplete(locationSelectorState.filter.isShowMeshNotComplete))
            } else {
                error(response?.payload?.errors[0]);
            }
        } catch (err) {
            error('システムに問題が発生しました。');
        }
    }

    const renderMenuDoing = () => {
        const locationStatus = locationSelectorState.locationList.data.find(location => location.id === locationSelectorState.locationSelect);
        if (locationStatus?.status === STATUS_MESH[1] || locationStatus?.status === STATUS_MESH[0]) {
            return <li className="disabled"><span>作業中</span></li>
        } else {
            return <li><a aria-hidden="true" onClick={() => {
                setShowModalStartStatus(true)
                if (document.getElementById("toggler-construction") !== undefined) {
                    /* @ts-ignore */
                    document.getElementById("toggler-construction").checked = false;
                }
            }}>作業中</a></li>
        }
    }

    const renderMenuSuccess = () => {
        const locationStatus = locationSelectorState.locationList.data.find(location => location.id === locationSelectorState.locationSelect);
        if (locationStatus?.status === STATUS_MESH[2]) {
            return <li className="disabled"><span>作業終了</span></li>
        } else {
            return <li><a aria-hidden="true" onClick={() => {
                setShowModalSuccessStatus(true);
                if (document.getElementById("toggler-construction") !== undefined) {
                    /* @ts-ignore */
                    document.getElementById("toggler-construction").checked = false;
                }
            }}>作業終了</a></li>
        }
    }

    const handleChangeLocation = async (event: any)  => {
        dispatch(locationSelectorActions.changeLocationSelect(event.target.value));
        dispatch(constructionActions.setStatusCallGetListMesh(false))
        dispatch(locationSelectorActions.changeFloorActive(-1))
        dispatch(locationSelectorActions.actionConstructionUpdate({
            ...locationSelectorState.filter,
            floorSelect: -1,
            showCompleted: false,
            istStatus: [],
        }))
        const resPumpList = await dispatch(shippingInformationActions.fetchPumpList({ LocationId: event.target.value }));

        if (shippingInformationState.pumpList[0]) {
            dispatch(locationSelectorActions.changePumpSelect(shippingInformationState.pumpList[0].id));
        }
        locationSelectorState.locationListSuccess.find(location => location.id === event.target.value);
        if (resPumpList.payload?.length > 0) {
            dispatch(locationSelectorActions.setModeSlim(resPumpList.payload[0].isSlim))
        }

        localStorage.setItem('currentLocationId', event.target.value);
    }

    const handlerSave = async (values: any) => {
        try {
            localStorage.setItem('meshSetting', JSON.stringify(values.data));
            dispatch(meshSettingActions.setMeshTransferTimeAll(values.data));
            setDataMeshSetting(values);
            setShowModal(false);
        } catch (error) {
            console.log('handlerSave: ', error)
        }
    }

    return (
        <>
            <LoadingSpinner isShowing={constructionState.isLoading || locationSelectorState.isLoading} />
            {!constructionState.isShowFullScreen && (
                <div className="header-construction">
                    <div className="title-header-construction text-center">
                        <img src="/assets/logo.png" alt="logo" className="ml-2" height="35" />
                    </div>
                    <div className="menu-construction-content">
                        <input type="checkbox" className="toggler-construction" id="toggler-construction" />
                        <div className={locationSelectorState.isModeSlim && locationSelectorState.isShowAlert ? 'hamburger-construction construction-alert' : 'hamburger-construction'} id="hamburger-construction"><div></div></div>
                        <div className="menu-construction">
                            <div>
                                <ul>
                                    <li><a aria-hidden="true" onClick={() => {
                                        setShow(true)
                                        if (document.getElementById("toggler-construction") !== undefined) {
                                            /* @ts-ignore */
                                            document.getElementById("toggler-construction").checked = false;
                                        }
                                    }}>メッシュ色凡例</a></li>
                                    <li><a aria-hidden="true" onClick={() => {
                                        const getSettingLocal = localStorage.getItem('meshSetting');
                                        if (getSettingLocal) {
                                            const dataMeshSettingLocal = JSON.parse(getSettingLocal);
                                            setDataMeshSetting({ data: dataMeshSettingLocal })
                                        }
                                        setShowModal(true);
                                        if (document.getElementById("toggler-construction") !== undefined) {
                                            /* @ts-ignore */
                                            document.getElementById("toggler-construction").checked = false;
                                        }
                                    }}>メッシュ透過率設定</a></li>
                                    <li>作業状態
                                        <ul>
                                            {renderMenuDoing()}
                                            {renderMenuSuccess()}
                                        </ul>
                                    </li>
                                    <li><a aria-hidden="true" onClick={() => {
                                        setShowModalDelete(true)
                                        if (document.getElementById("toggler-construction") !== undefined) {
                                            /* @ts-ignore */
                                            document.getElementById("toggler-construction").checked = false;
                                        }
                                    }}>開始取消</a></li>
                                    <li><a aria-hidden="true" onClick={() => {
                                        if (showMode === 1) {
                                            changeShowMode(2);
                                            dispatch(locationSelectorActions.changeModeShow3d(false));
                                            dispatch(locationSelectorActions.changeFloorActive(1));
                                            dispatch(locationSelectorActions.actionConstructionUpdate({
                                                ...locationSelectorState.filter,
                                                floorSelect: 1,
                                            }));
                                        } else {
                                            changeShowMode(1);
                                            dispatch(locationSelectorActions.changeModeShow3d(true));
                                            dispatch(locationSelectorActions.changeFloorActive(-1));
                                            dispatch(locationSelectorActions.actionConstructionUpdate({
                                                ...locationSelectorState.filter,
                                                floorSelect: -1,
                                            }));
                                        }
                                        if (document.getElementById("toggler-construction") !== undefined) {
                                            /* @ts-ignore */
                                            document.getElementById("toggler-construction").checked = false;
                                        }
                                    }}>{showMode === 1 ? '2D' : '3D'}表示へ切替</a></li>
                                    <li><a aria-hidden="true" onClick={() => dispatch(authActions.logoutWorkingSite())}>ログアウト</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!constructionState.isShowFullScreen && (
                <div className="section hiddenBlockSuccess-content">
                    <div className="col-12 d-flex">
                        <div className="content-form-check d-flex align-items-center">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={locationSelectorState.filter.isShowMeshNotComplete}
                                    id="hiddenBlockSuccess"
                                    onChange={() => dispatch(locationSelectorActions.changeShowMeshNotComplete(!locationSelectorState.filter.isShowMeshNotComplete))} />
                                <label className="form-check-label text-show-mode text-white" htmlFor="hiddenBlockSuccess">終了分を非表示</label>
                            </div>
                        </div>
                        {showMode === 1 && (
                            <div className="switch-show-mode">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="text-white text-show-mode">層の表示方法</div>
                                    <div className="ml-5">
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="options-outlined"
                                            id="full-layer"
                                            autoComplete="off"
                                            checked={locationSelectorState.modeShowFloor === 2}
                                            onChange={() => {
                                                dispatch(locationSelectorActions.setModeShowFloor(2));
                                                if (locationSelectorState.floorActive > -1) {
                                                    dispatch(locationSelectorActions.actionConstructionUpdate(locationSelectorState.filter));
                                                }
                                            }}
                                        />
                                        <label className={`btn btn-show-mode btn-sm ${locationSelectorState.modeShowFloor === 2 ? 'btn btn-outline-warning' : 'btn-light'}`} htmlFor="full-layer">全層</label>
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="options-outlined"
                                            id="washing-tub"
                                            autoComplete="off"
                                            checked={locationSelectorState.modeShowFloor === 1}
                                            onChange={() => {
                                                dispatch(locationSelectorActions.setModeShowFloor(1));
                                                if (locationSelectorState.floorActive > -1) {
                                                    dispatch(locationSelectorActions.actionConstructionUpdate(locationSelectorState.filter));
                                                }
                                            }}
                                        />
                                        <label className={`btn btn-show-mode ml-5 btn-sm ${locationSelectorState.modeShowFloor === 1 ? 'btn btn-outline-warning' : 'btn-light'}`} htmlFor="washing-tub">選択層のみ</label>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            )}
            {!constructionState.isShowFullScreen && (
                <div className="section list-select-construction">
                    <div className="d-flex">
                        <div className="col-7">
                            <select className="form-select" value={locationSelectorState.locationSelect} onChange={event =>  handleChangeLocation(event)}>
                                {locationSelectorState.locationListSuccess?.map(itemLocation => (
                                    <option key={itemLocation.id} value={itemLocation.id}>{itemLocation.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-5">
                            <select className="form-select" value={locationSelectorState.pumpSelect} onChange={async (event: any) => {
                                await dispatch(locationSelectorActions.changePumpSelect(event.target.value));
                                const pumpItem = shippingInformationState.pumpList.find(item => item.id === event.target.value);
                                if (pumpItem) {
                                    dispatch(locationSelectorActions.setModeSlim(pumpItem.isSlim))
                                }
                            }}>
                                {shippingInformationState.pumpList?.map(itemPump => (
                                    <option key={itemPump.id} value={itemPump.id}>{itemPump.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            )}

            <div className="section position-relative">
                {locationSelectorState.isShowAlert && !locationSelectorState.isModeSlim && (<div className={`alert-message-slim text-white position-absolute ${showMode !== 1 ? 'alert-message-slim-2d' : ''}`}>薄層打設済みのメッシュがあります。</div>)}
                {showMode === 1 ? <ThreeEditor /> : <Mode2dEditor />}
            </div>
            {!constructionState.isShowFullScreen && (
                <div className="section pb-2 list-view-info-location">
                    <span className="icon-change-mode-view"><i className="fas fa-retweet" aria-hidden="true" onClick={() =>
                        dispatch(constructionActions.setShowTableMesh(!constructionState.isShowTableMesh))}></i></span>
                    {!constructionState.isShowTableMesh ? <ListMesh /> : <ShippingInformationList />}
                </div>
            )}
            <Modal size="sm" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="font-14">メッシュ色凡例</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <table className="table table-bordered table-sm">
                        <thead>
                            <tr>
                                <th>色</th>
                                <th>ステータス</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>白</td>
                                <td>未打設</td>
                            </tr>
                            <tr className="bg-during-casting">
                                <td>黄</td>
                                <td>打設中</td>
                            </tr>
                            <tr className="bg-end-casting">
                                <td>黄緑</td>
                                <td>打設終了</td>
                            </tr>
                            <tr className="bg-warning-time-exceeded">
                                <td>ピンク</td>
                                <td>警告時間超過</td>
                            </tr>
                            <tr className="bg-time-limit-exceeded">
                                <td>赤</td>
                                <td>制限時間超過</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
            <Modal show={showModalDelete}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="text-center">
                    <div className="content-confirm">
                        <div className="">打設中のすべてのメッシュを一括で取り消します。よろしいですか。</div>
                        <div className="d-flex justify-content-evenly pt-2">
                            <button className="btn btn-mesh-action btn-primary mr-2" onClick={revertByPump}>OK</button>
                            <button className="btn btn-mesh-action btn-secondary" onClick={() => setShowModalDelete(false)}>キャンセル</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showModalStartStatus}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="text-center">
                    <div className="content-confirm">
                        <div className="">打設場所を作業中に変更します。よろしいですか。</div>
                        <div className="d-flex justify-content-evenly pt-2">
                            <button className="btn btn-mesh-action btn-primary mr-2" onClick={callApiStartStatus}>OK</button>
                            <button className="btn btn-mesh-action btn-secondary" onClick={() => setShowModalStartStatus(false)}>キャンセル</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showModalSuccessStatus}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="text-center">
                    <div className="content-confirm">
                        <div className="">打設場所を作業終了に変更します。よろしいですか。</div>
                        <div className="d-flex justify-content-evenly pt-2">
                            <button className="btn btn-mesh-action btn-primary mr-2" onClick={callApiSuccessStatus}>OK</button>
                            <button className="btn btn-mesh-action btn-secondary" onClick={() => setShowModalSuccessStatus(false)}>キャンセル</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={isShowModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header>メッシュ透過率設定</Modal.Header>
                <Modal.Body className="text-center">
                    <Formik
                        enableReinitialize
                        initialValues={dataMeshSetting}
                        validationSchema={meshSettingValidationSchema}
                        onSubmit={(values, actions) => {
                            handlerSave(values);
                            actions.setSubmitting(false)
                        }}
                    >
                        {({ values, handleReset }) => (
                            <Form className="form-update">
                                <div className="checkbox mb-3 d-flex justify-content-end">
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                        handleReset();
                                        getInfoMeshSetting();
                                    }}>リセット</button>
                                </div>
                                <div>
                                    <table className="table table-bordered">
                                        <tbody>
                                            {values.data?.map((value: any, index: number) => (
                                                <tr key={value.id}>
                                                    <td>{value.name}</td>
                                                    <td>{value.colorName}</td>
                                                    <td>
                                                        <Field type="number" name={`data.${index}.transferTime`} className="form-control w-100" />
                                                        <ErrorMessage name={`data.${index}.transferTime`}>
                                                            {msg => <div className="text-danger">{msg}</div>}
                                                        </ErrorMessage>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-4 text-center d-flex justify-content-evenly">
                                    <Button className="w-120" variant="primary" type="submit">登録</Button>
                                    <Button className="w-120" variant="secondary" onClick={() => {
                                        setShowModal(false);
                                    }}>
                                        キャンセル
                                    </Button>
                                    </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default connect((state: RootState) => ({
    locationSelectorState: state.locationSelector,
    shippingInformationState: state.shippingInformation,
    constructionState: state.construction,
    meshSettingState: state.meshSetting,
}))(ConstructionPage);
