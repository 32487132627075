import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { WarningTimeSettingState, Dispatcher, RootState } from "AppTypes";
import { warningTimeSettingActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { success, error } from '@services/toast-service'
import "./WarningTimeSetting.css";
import { VALIDATE_NUMBER } from "@const/constants";

type Props = {
    dispatch: Dispatcher;
    warningTimeSettingState: WarningTimeSettingState;
};


const WarningTimeSetting: React.FC<Props> = ({ warningTimeSettingState, dispatch }) => {
    const { isLoading, warningTimeSetting } = warningTimeSettingState;
    const { beginDeliveryLimit, beginDeliveryWarningTime, endDeliveryLimit, endDeliveryWarningTime, overlapLimitTime, overlapLimitWarningTime } = warningTimeSetting;
    const history = useHistory();

    useEffect(() => {
        dispatch(warningTimeSettingActions.fetch());
    }, []);



    const handleSave = async () => {
        if (!beginDeliveryLimit || (beginDeliveryLimit && !VALIDATE_NUMBER.test(beginDeliveryLimit.toString())))
        {
            error("打設制限時間は数値で入力してください。")
            return;
        }
        if (!beginDeliveryWarningTime || (beginDeliveryWarningTime && !VALIDATE_NUMBER.test(beginDeliveryWarningTime.toString())))
        {
            error("荷卸開始警告時間は数値で入力してください。")
            return;
        }
        if (!endDeliveryWarningTime || (endDeliveryWarningTime && !VALIDATE_NUMBER.test(endDeliveryWarningTime.toString())))
        {
            error("荷卸終了警告時間は数値で入力してください。")
            return;
        }
        if (!overlapLimitTime || (overlapLimitTime && !VALIDATE_NUMBER.test(overlapLimitTime.toString())))
        {
            error("打重ね制限時間は数値で入力してください。")
            return;
        }
        if (!overlapLimitWarningTime || (overlapLimitWarningTime && !VALIDATE_NUMBER.test(overlapLimitWarningTime.toString())))
        {
            error("打重ね警告時間は数値で入力してください。")
            return;
        }

        if (beginDeliveryLimit > 150 || beginDeliveryWarningTime > 150 || endDeliveryWarningTime > 150 || overlapLimitTime > 150 || overlapLimitWarningTime > 150)
        {
            error("150分より大きな値は設定できません。")
            return;
        }

        if (beginDeliveryLimit <= beginDeliveryWarningTime) {
            error("荷卸開始警告時間は、打設制限時間より短くしてください。")
            return;
        }

        try {
            const response = await dispatch(warningTimeSettingActions.update(warningTimeSetting));
            if (response.payload && response.payload.success) {
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleUpdateData = (key: string, value: string) => {
        dispatch(warningTimeSettingActions.setData({ key, value }));
    }

    return (
        <>
            <LoadingSpinner isShowing={isLoading} />
            <div className="settingItemPageTitle">
                <p>アラート時間設定</p>
            </div>
            <div className="setting-container d-flex justify-content-center">
                <table className="table table-striped table-bordered w-50">
                    <thead>
                        <tr>

                            <th scope="col">表示色</th>
                            <th scope="col">設定項目</th>
                            <th scope="col">設定内容</th>
                        </tr>
                    </thead>
                    <tbody className="align-middle">
                        <tr>
                            <td style={{ backgroundColor: "#FF0000" }}></td>
                            <td>打設制限時間（分）</td>
                            <td>
                                <input type="text"
                                    value={warningTimeSetting.beginDeliveryLimit}
                                    className="form-control"
                                    onChange={(e) => handleUpdateData("beginDeliveryLimit", e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: "#FF66FF" }}></td>
                            <td>荷卸開始警告時間（分）</td>
                            <td>
                                <input type="text"
                                    value={warningTimeSetting.beginDeliveryWarningTime}
                                    className="form-control"
                                    onChange={(e) => handleUpdateData("beginDeliveryWarningTime", e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: "#FF66FF" }}></td>
                            <td>荷卸終了警告時間（分）</td>
                            <td>
                                <input type="text"
                                    value={warningTimeSetting.endDeliveryWarningTime}
                                    className="form-control"
                                    onChange={(e) => handleUpdateData("endDeliveryWarningTime", e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: "#FF0000" }}></td>
                            <td>打重ね制限時間（分）</td>
                            <td>
                                <input type="text"
                                    value={warningTimeSetting.overlapLimitTime}
                                    className="form-control"
                                    onChange={(e) => handleUpdateData("overlapLimitTime", e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: "#FF66FF" }}></td>
                            <td>打重ね警告時間（分）</td>
                            <td>
                                <input type="text"
                                    value={warningTimeSetting.overlapLimitWarningTime}
                                    className="form-control"
                                    onChange={(e) => handleUpdateData("overlapLimitWarningTime", e.target.value)} />
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div className="goHomeButtonContainer mb-5">
                <input type="button" value="登録" onClick={() => { handleSave() }} className="goHomeButton" />
                <input type="button" value="戻る " onClick={() => { history.goBack() }} className="goHomeButton ml-5" />
            </div>
        </>
    );
};

export default connect((state: RootState) => ({
    warningTimeSettingState: state.warningTimeSetting
}))(WarningTimeSetting);
