import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { Table } from 'reactstrap'
import { ShippingInformationState, Dispatcher, RootState, DisplayItemSettingState, IDisplayItemSetting, AppState } from "AppTypes";
import { connect } from "react-redux";
import { shippingInformationActions } from "./state_management/actions";
import { displayItemSettingActions } from "../setting/displayitem/state_management/actions";
import { TShippingInformationManagement } from "./state_management/types";
import moment from "moment";
import "./ShippingInformationList.css";
import { convertMinuteToHour } from "@utils/format";
import { locationSelectorActions } from "@features/location/selector/state_management/actions";

type Props = {
    dispatch: Dispatcher;
    shippingInformationState: ShippingInformationState;
    displayItemSettingState: DisplayItemSettingState;
    appState: AppState
};

const ShippingInformationList: React.FC<Props> = ({ shippingInformationState, appState, displayItemSettingState, dispatch }) => {
    const { shippingInformationList } = shippingInformationState;
    const { displayItemSettingList } = displayItemSettingState;
    const history = useHistory();
    const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || ''
    const [sortDirection, setSortDirection] = useState<string>("ASC");
    const [sortColumn, setSortColumn] = useState<string>("");

    const compare = (a: IDisplayItemSetting, b: IDisplayItemSetting) => {
        if (a.displayOrder < b.displayOrder) {
            return -1;
        }
        if (a.displayOrder > b.displayOrder) {
            return 1;
        }
        return 0;
    }
    useEffect(() => {

        if (locationId) {
            dispatch(shippingInformationActions.fetchList({
                locationId: locationId
            }));
        } else {
            history.push("/locationselector")
        }

        dispatch(displayItemSettingActions.fetchList(null));
    }, [locationId]);

    const shippingSettings = displayItemSettingList?.filter(x => x.isDisplayed && x.type === "Shipping").sort(compare);
    const sortedSettings = shippingSettings?.map(x => {
        return { ...x, code: x.code.toUpperCase() };
    })

    const statuses = [{ key: "NotStartYet", value: "まだ" },
    { key: "Moving", value: "まだ" },
    { key: "Arrived", value: "現着" },
    { key: "Unloading", value: "荷卸中" },
    { key: "Completed", value: "終了" }]


    const sortList = (id: string) => {
        const displayItem = shippingSettings.find(x => x.id === id);
        if (!displayItem) return;

        const sortCol= displayItem.code;

        let sortDir = sortDirection;

        if (sortCol === sortColumn) {
            if (sortDirection === "ASC") {
                sortDir = "DESC";
            } else if (sortDirection === "DESC") {
                sortDir = "ASC"
            }

        } else {
            
            sortDir = "ASC"
        }

        setSortDirection(sortDir);
        setSortColumn(sortCol);

        dispatch(shippingInformationActions.fetchList({
            locationId: locationId,
            sortColumn: sortCol,
            sortDirection: sortDir
        }));
    }


    const getBackgoundClass = (item: any) => {
        let className = '';
        if (item.status === "Completed") {
            className = "bg-end-casting";
        } else if (item.status === "Unloading") {
            className = "bg-warning";
        }
        else if (item.status === "Moving") {
            className = "bg-moving";
        }
        else if (item.status === "Arrived") {
            className = "bg-info";
        }
        if (shippingInformationState.listShippingId.includes(item.id)) {
            className += ' text-danger'
            if (shippingInformationState.listShippingId?.length > 0) {
                const itemScroll = document.getElementById(shippingInformationState.listShippingId[0]);
                if (itemScroll) {
                    setTimeout(function () {
                        itemScroll.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "nearest"
                        });
                    }, 100);
                }
            }
        }
        return className;
    }

    const renderHeaders = sortedSettings?.length ? (
        sortedSettings.map((setting: IDisplayItemSetting) => (
            <td key={setting.id} onClick={() => sortList(setting.id)} className={setting.code.toUpperCase() === sortColumn.toUpperCase() ? "sortedClicked cursor-pointer" : "cursor-pointer"}>
                {
                    setting.name
                }
            </td>
        ))
    ) : (

        <th></th>
    );

    const clickActiveRowTable = (item: TShippingInformationManagement.ShippingInformation, event: any) => {
        event.preventDefault();
        dispatch(shippingInformationActions.setShippingToList(item.id));
        if (!shippingInformationState.listShippingId.includes(item.id)) {
            dispatch(locationSelectorActions.changeMeshActiveList({
                type: 1,
                listMesh: item?.meshCodes ?? [],
            }))
        } else {
            dispatch(locationSelectorActions.changeMeshActiveList({
                type: 2,
                listMesh: item?.meshCodes ?? [],
            }))
        }
    }

    const renderClassWarning = (item: TShippingInformationManagement.ShippingInformation, prop: any) => {
        let className = '';
        if (prop === 'pumpStartTime' || prop === 'pumpFinishTime') {
            if (item.pumpStartWarning === 'WarningTimeLimit' || item.pumpFinishWarning === 'WarningTimeLimit') {
                //className = 'bg-warning-item'
            } else if (item.pumpStartWarning === 'WarningTimeStart' || item.pumpFinishWarning === 'WarningTimeFinish') {
                //className = 'bg-danger-item'
            }
        }

        return className;
    }


    return (
        <>
            <div className="bg-warning text-center" style={{ fontWeight: "bold" }}>運搬情報一覧</div>
            <div className="scrollableContainer table-wrapper-scroll-x">
                <Table bordered className="text-center table-mesh table-shipping-information" style={{ width: "1000px", whiteSpace: "nowrap" }}>
                    <thead>
                        <tr>
                            {renderHeaders}
                        </tr>
                    </thead>
                    <tbody style={{ color: "black" }} className="table-shipmentlist-bordered">
                        {shippingInformationList && shippingInformationList?.map(function (si: TShippingInformationManagement.ShippingInformation) {
                            const itemProperties = Object.keys(si);
                            return (
                                <tr key={si.id} className={getBackgoundClass(si)} onClick={(event) => clickActiveRowTable(si, event)} id={si.id}>
                                    {
                                        sortedSettings?.map(function (setting: IDisplayItemSetting) {
                                            const prop = itemProperties.find(x => x.toUpperCase() === setting.code.toUpperCase());

                                            if (prop) {

                                                if (setting.dataType === "String") {
                                                    return (<td key={`${si.id}-${setting.id}`}>{si[prop]}</td>)
                                                } else if (setting.dataType === "Number") {
                                                    return (<td key={`${si.id}-${setting.id}`}>{si[prop]?.toFixed(2)}</td>)
                                                } else if (setting.dataType === "DateTime") {

                                                    return (<td key={`${si.id}-${setting.id}`} className={renderClassWarning(si, prop)}>{si[prop] ? moment(si[prop]).format("YYYY/MM/DD HH:mm") : ""}</td>)

                                                } else if (setting.dataType === "TimeSpan") {
                                                    return (<td key={`${si.id}-${setting.id}`}>{si[prop] ? convertMinuteToHour(parseInt(si[prop])) : ""}</td>)

                                                } else if (setting.dataType === "Boolean") {
                                                   
                                                    if (si[prop] === true) {
                                                        if (setting.code == 'ISCOMPRESSIVEEXPERIMENTED') {
                                                            return (<td key={`${si.id}-${setting.id}`} >
                                                                <a href={'/setting/compressionexperiment?shipmentId=' + si.id}>
                                                                    {si.code}
                                                                </a>
                                                            </td>)
                                                        } else {
                                                            return (<td key={`${si.id}-${setting.id}`} >●</td>)
                                                        }
                                                        
                                                    } else {
                                                        <td key={`${si.id}-${setting.id}`} />
                                                    }
                                                } else {
                                                    return (<td key={`${si.id}-${setting.id}`} />)
                                                }
                                            }

                                            return (<td key={`${si.id}-${setting.id}`} />)
                                        })
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </Table >
            </div>
        </>
    )
}

export default connect((state: RootState) => ({
    shippingInformationState: state.shippingInformation,
    displayItemSettingState: state.dipslayItemSetting,
    appState: state.app
}))(ShippingInformationList);
