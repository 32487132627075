import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { SlumpInfoState, Dispatcher, RootState, AppState, IShippingInformationBrief, ISlumpInfo } from "AppTypes";
import { slumpInfoActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { success, error } from '@services/toast-service'
import moment from "moment";

type Props = {
    dispatch: Dispatcher;
    slumpInfoState: SlumpInfoState;
    appState: AppState
};


const SlumpSetting: React.FC<Props> = ({ slumpInfoState, appState, dispatch }) => {

    const { slumpInfo, isLoading, shippingInformationList } = slumpInfoState;


    const history = useHistory()
    const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || ''
    const [showExperimentedOnly, setShowExperimentedOnly] = useState(false)


    useEffect(() => {

        if (!locationId) {
            history.push("/locationselector");
        } else {
            dispatch(slumpInfoActions.fetchShippingInfoList(locationId));
        }

    }, [locationId]);

    useEffect(() => {
        if (slumpInfo.id) {
            dispatch(slumpInfoActions.fetchSlumpInfo(slumpInfo.id));
        }

    }, [slumpInfo.id]);
    const handleGoBack = () => {
        history.goBack();
    }

    const handleChangeSlumpInfoData = (key: string, value: string | number) => {
        dispatch(slumpInfoActions.setSlumpInfoData({ key, value }));
    }
    const handleSave = async () => {

        if (!slumpInfo.id) {
            error("保存ができませんでした。");
            return;
        }


        try {
            const response = await dispatch(slumpInfoActions.updateSlumpInfo(slumpInfo));
            if (response.payload && response.payload.success) {
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleClickShowExperimentedOnly = async (checkbox: HTMLInputElement) => {
        setShowExperimentedOnly(checkbox.checked);
    }

    const getDataList = () => {
        if (showExperimentedOnly) {
            return shippingInformationList.filter(x => x.isExperimented);
        } else {
            return shippingInformationList;
        }
    }

    return (
        <>
            <LoadingSpinner isShowing={isLoading} />
            <div className="settingItemPageTitle">
                <p>スランプ登録</p>
            </div>


            <div className="row setting-container">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" onChange={({ currentTarget }) => handleClickShowExperimentedOnly(currentTarget)} />
                    <label className="form-check-label" htmlFor="ckShowExperimentedOnly">
                        試験対象車両のみを表示
                    </label>
                </div>
            </div>

            <div className="row setting-container slumplist">

               
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">出荷工場</th>
                            <th scope="col">車両番号</th>
                            <th scope="col">出荷時刻</th>
                            <th scope="col">現着時刻</th>
                            <th scope="col" />
                        </tr>
                    </thead>
                    <tbody>
                        {getDataList()?.length ? getDataList().map((si: IShippingInformationBrief) => (

                            <tr>
                                <td>{si.factory}</td>
                                <td>
                                    {si.truckCode}
                                </td>
                                <td>
                                    {si.transferTime ? moment(si.transferTime).format("HH:mm") : ""}
                                </td>
                                <td>
                                    {si.arrivalTime ? moment(si.arrivalTime).format("HH:mm") : ""}
                                </td>
                                <td>
                                    <input type="button" name="btnUpdateCancel" value="選択" onClick={(e) => handleChangeSlumpInfoData("id", si.id)}/>
                                </td>
                            </tr>

                        )) : (<tr><td colSpan={5}>データなし</td></tr>)}



                    </tbody>
                </table>
            </div>


            <div className="row setting-container" role="form" hidden={!slumpInfo.id}>
                <div className="form-group mt-3">
                    <label className="control-label">車両番号</label>
                    <div>
                        <input
                            className="form-control d-inline"
                            value={slumpInfo.truckCode}
                            disabled
                            type="text"
                        />
                    </div>

                </div>
                <div className="form-group mt-3">
                    <label className="control-label">配合</label>
                    <div>
                        <input
                            className="form-control d-inline"
                            value={slumpInfo.mixture}
                            disabled
                            type="text"
                        />
                    </div>

                </div>

                <div className="form-group mt-3">
                    <label className="control-label">出荷量</label>
                    <div>
                        <input
                            className="form-control d-inline"
                            value={slumpInfo.transferAmount.toFixed(2)}
                            disabled
                            type="number"
                        />
                    </div>

                </div>
                <div className="form-group mt-3">
                    <label className="control-label">出荷日時</label>
                    <div>
                        <input
                            className="form-control d-inline"
                            value={slumpInfo.transferTime ? moment(slumpInfo.transferTime).format("YYYY/MM/DD HH:mm") : ""}
                            disabled
                            type="text"
                        />
                    </div>

                </div>
                <div className="form-group mt-3">
                    <label className="control-label">現場到着</label>
                    <div>
                        <input
                            className="form-control d-inline"
                            value={slumpInfo.arrivalTime ? moment(slumpInfo.arrivalTime).format("YYYY/MM/DD HH:mm") : ""}
                            disabled
                            type="text"
                        />
                    </div>

                </div>

                <div className="form-group mt-3">
                    <label className="control-label">スランプ</label>
                    <div>
                        <input
                            className="form-control d-inline"
                            value={slumpInfo.slumpAmount?.toFixed(2) ?? 0}
                            type="number"
                            onChange={(e) => { handleChangeSlumpInfoData("slumpAmount", parseFloat(e.target.value)) }}
                        />
                    </div>

                </div>
                <div className="form-group mt-3">
                    <label className="control-label">空気量</label>
                    <div>
                        <input
                            className="form-control d-inline"
                            value={slumpInfo.airAmount?.toFixed(2) ?? 0}
                            type="number"
                            onChange={(e) => { handleChangeSlumpInfoData("airAmount", parseFloat(e.target.value)) }}
                        />
                    </div>

                </div>
                <div className="form-group mt-3">
                    <label className="control-label">Con温度</label>
                    <div>
                        <input
                            className="form-control d-inline"
                            value={slumpInfo.conTemperature?.toFixed(2) ?? 0}
                            type="number"
                            onChange={(e) => { handleChangeSlumpInfoData("conTemperature", parseFloat(e.target.value)) }}
                        />
                    </div>

                </div>
                <div className="form-group mt-3">
                    <label className="control-label">外気温</label>
                    <div>
                        <input
                            className="form-control d-inline"
                            value={slumpInfo.outsideTemperature?.toFixed(2) ?? 0}
                            type="number"
                            onChange={(e) => { handleChangeSlumpInfoData("outsideTemperature", parseFloat(e.target.value)) }}
                        />
                    </div>

                </div>
                <div className="form-group mt-3">
                    <label className="control-label">単位水量</label>
                    <div>
                        <input
                            className="form-control d-inline"
                            value={slumpInfo.unitWaterVolume?.toFixed(2) ?? 0}
                            type="number"
                            onChange={(e) => { handleChangeSlumpInfoData("unitWaterVolume", parseFloat(e.target.value)) }}
                        />
                    </div>

                </div>
                <div className="form-group mt-3">
                    <label className="control-label">塩化物量</label>
                    <div>
                        <input
                            className="form-control d-inline"
                            value={slumpInfo.cloAmount?.toFixed(2) ?? 0}
                            type="number"
                            onChange={(e) => { handleChangeSlumpInfoData("cloAmount", parseFloat(e.target.value)) }}
                        />
                    </div>

                </div>
            </div>
            <div className="goHomeButtonContainer">
                {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                <input type="button" value="登録" onClick={handleSave} className="goHomeButton" />
                )}
                <input type="button" value="戻る" onClick={handleGoBack} className="goHomeButton ml-5" />
            </div>
        </>

    );
};

export default connect((state: RootState) => ({
    slumpInfoState: state.slumpInfo,
    appSate: state.app
}))(SlumpSetting);
