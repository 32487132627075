import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { UserSettingState, Dispatcher, RootState, IUserModel, IFactoryGetAllModel } from "AppTypes";
import { userSettingActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Modal } from "../../../components/Modal";
import { success, error } from '@services/toast-service'
import { VALIDATE_ALPHANUM, VALIDATE_ALPHANUM_FULLWIDTH, VALIDATE_PASSWORD } from "@const/constants";
import { Tooltip } from 'reactstrap';

type Props = {
    dispatch: Dispatcher;
    userSettingState: UserSettingState;
};


const UserList: React.FC<Props> = ({ userSettingState, dispatch }) => {
    const { userList, insert, update, deleteId, isLoading, factoryList } = userSettingState;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const history = useHistory()
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    useEffect(() => {
        dispatch(userSettingActions.fetchList());
        dispatch(userSettingActions.fetchFactoryList());
    }, []);

    const roles = [
        { key: "Office", value: "事務所" },
        { key: "WorkingSite", value: "打設現場" },
        { key: "Location", value: "現場到着" },
        { key: "PumpingManagement", value: "荷卸管理" },
        { key: "LocationPumpingManagement", value: "現場到着＆荷卸管理" },
        { key: "Factory", value: "生コン工場" }

    ]

    const getRoleName = (key: string) => {
        const role = roles.find(x => x.key === key);
        if (role) {
            return role.value;
        }
        return "";
    }
    const handleAddButtonClick = async () => {

        if (!insert.userName) {
            error("ユーザIDを入力してください。");
            return;
        }
        if (!VALIDATE_ALPHANUM.test(insert.userName) || insert.userName.length > 20) {
            error("ユーザIDは半角英数字20文字以下で入力してください。");
            return;
        }

        if (!insert.passWord) {
            error("パスワードを入力してください。");
            return;
        }
        
        if (!VALIDATE_ALPHANUM.test(insert.passWord) || insert.passWord.length > 20) {
            error("パスワードは半角英数字20文字以下で入力してください。");
            //error("パスワードは・・・");
            return;
        }

        if (!insert.displayName) {
            error("表示名を入力してください。");
            return;
        } 
        if (insert.displayName.length > 20) {
            error("表示名は20文字以下で入力してください。");
            return;
        } 
        
        if (!insert.role) {
            error("ユーザ種別を選択してください。");
            return;
        } else if (insert.role === "Factory" && !insert.factoryId) {
            error("出荷工場を選択してください。");
            return;
        }


        const postedData = { ...insert, factoryId: insert.factoryId || null }

        try {
            const response = await dispatch(userSettingActions.insert(postedData));
            if (response.payload && response.payload.success) {
                await dispatch(userSettingActions.fetchList());
                await dispatch(userSettingActions.resetInsert({}));
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleUpdateButtonClick = async (id: string) => {
        await dispatch(userSettingActions.setUpdatingId(id));
    }

    const handleUpdateCancelButtonClick = async (id: string) => {
        await dispatch(userSettingActions.setUpdatingId(''));

    }

    const handleDeleteCancelButtonClick = async () => {
        setShowDeleteConfirmModal(false);
        await dispatch(userSettingActions.setDeletingId(''));

    }


    const handleUpdateButtonSaveClick = async (id: string) => {
        if (update.displayName.length > 20 || update.displayName === '') {
            error("表示名は20文字以下で入力してください。");
            return;
        }

        if (!update.role) {
            error("ユーザ種別を選択してください。");
            return;
        } else if (update.role === "Factory" && !update.factoryId) {
            error("出荷工場を選択してください。");
            return;
        }
        
        if (update.passWord && (!VALIDATE_ALPHANUM.test(update.passWord) || update.passWord.length > 20)) {
            error("パスワードは半角英数字20文字以下で入力してください。");
            //error("パスワードは・・・");
            return;
        }

        const postedData = { ...update, factoryId: update.factoryId || null }

        try {
            const response = await dispatch(userSettingActions.update(update));
            if (response.payload && response.payload.success) {
                await dispatch(userSettingActions.fetchList());
                await dispatch(userSettingActions.resetInsert({}));
                success("保存が完了しました。");
                await dispatch(userSettingActions.setUpdatingId(''));

            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleDeleteButtonClick = async (id: string) => {
        await dispatch(userSettingActions.setDeletingId(id));
        setShowDeleteConfirmModal(true);
    }

    const handleChangeInsertData = (key: string, value: string | number | boolean) => {
        dispatch(userSettingActions.setInsertData({ key, value }));
    }

    const handleChangeRowDataValue = (key: string, value: string | number | boolean) => {
        dispatch(userSettingActions.setUpdateData({ key, value }));
    }


    const handleDeleteConfirmButtonClick = async () => {
        const response = await dispatch(userSettingActions.deleteOne(deleteId));
        if (response.payload && response.payload.success) {
            await dispatch(userSettingActions.fetchList());
            setShowDeleteConfirmModal(false);
            success("保存が完了しました。");
        } else {
            setShowDeleteConfirmModal(false);
            error(response.payload.errors[0])
        }


    }


    const renderList = userList?.length ? (
        userList.map((user: IUserModel) => (
            <tr key={user.id} className={`${user.id !== update.id ? '' : 'edit-active'}`}>
                <td style={{ width: `${user.id !== update.id ? '15%' : '20%'}` }}>
                    {
                        user.id !== update.id ?
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="編" onClick={() => handleUpdateButtonClick(user.id)} />
                                    <input type="button" name="btnRowDelete" value="削" onClick={() => handleDeleteButtonClick(user.id)} className="ml-3" />
                                </>
                            )
                            :
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="更新" onClick={() => handleUpdateButtonSaveClick(user.id)} />
                                    <input type="button" name="btnRowDelete" value="キャンセル" onClick={() => handleUpdateCancelButtonClick(user.id)} className="ml-3" />
                                </>
                            )

                    }

                </td>

                <td style={{ width: "15%" }}>
                    {
                        user.userName
                    }

                </td>
                <td style={{ width: "15%" }}>
                    {
                        user.id !== update.id ? "******" :
                            (<input type="password" required className="form-control w-100" value={update.passWord} onChange={(e) => { handleChangeRowDataValue('passWord', e.target.value) }} />)

                    }
                </td>
                <td>
                    {
                        (user.id === update.id) ?
                            (<input type="text" required className="form-control w-100" value={update.displayName} onChange={(e) => { handleChangeRowDataValue('displayName', e.target.value) }} />)
                            : user.displayName
                    }
                </td>
                <td style={{ width: "10%" }}>
                    {getRoleName(user.role)}

                </td>
                <td>
                    {
                        (user.id === update.id && user.role == "Factory") ? (
                            <select
                                className="form-select w-100"
                                defaultValue={update.factoryId ?? ""}
                                onBlur={(e: { currentTarget: HTMLSelectElement }) => { handleChangeRowDataValue("factoryId", e.currentTarget.value) }}
                            >
                                <option value=""></option>
                                {factoryList?.length && factoryList.map((p: IFactoryGetAllModel) => (
                                    <option key={p.id} value={p.id}>{p.name}</option>
                                ))}
                            </select>
                        ) : user.factory
                    }
                </td>
                {/* <td hidden={!update.id}>
                    {
                        (user.id === update.id && user.role == "Factory") ?
                            (

                                <select
                                    className="form-select-office"
                                    defaultValue={update?.isActive ? "1" : "0"}
                                    onBlur={(e: { currentTarget: HTMLSelectElement }) => { handleChangeRowDataValue("isActive", e.currentTarget.value === "1") }}
                                >
                                    <option value="1">有効</option>
                                    <option value="0">無効</option>

                                </select>) : (user.isActive ? "有効" : "無効")

                    }


                </td> */}
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={6}>データなし</td>
        </tr>
    );
    return (
        <>
            <LoadingSpinner isShowing={isLoading} />
            <div className="settingItemPageTitle">
                <p>ユーザ設定</p>
            </div>
            <div className="content setting-container">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th />
                            <th scope="col">ユーザID</th>
                            <th scope="col">パスワード　
                                <i className="fas fa-question-circle" id="showDescription" aria-hidden="true"></i>
                                <Tooltip placement="top" isOpen={tooltipOpen} autohide={false} target="showDescription" toggle={() => setTooltipOpen(!tooltipOpen)}>
                                    <div className="w-100">パスワードは8文字以上で、英小文字、数字をそれぞれ1文字以上使用してください。</div>
                                </Tooltip>
                            </th>
                            <th scope="col">表示名</th>
                            <th scope="col">ユーザ種別</th>
                            <th scope="col">出荷工場</th>
                            {/* <th scope="col" hidden={!update.id}>アカウント状態</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {renderList}
                        <tr className="insertform">
                            <td>
                                <input type="button" name="btnAdd" value="追加" onClick={handleAddButtonClick} />
                            </td>

                            <td><input type="text" className="form-control w-100" value={insert.userName} onChange={(e) => { handleChangeInsertData('userName', e.target.value) }} /></td>
                            <td><input type="text" className="form-control w-100" value={insert.passWord} onChange={(e) => { handleChangeInsertData('passWord', e.target.value) }} /></td>
                            <td><input type="text" className="form-control w-100" value={insert.displayName} onChange={(e) => { handleChangeInsertData('displayName', e.target.value) }} /></td>
                            <td>
                                <select
                                    className="form-select w-100"
                                    defaultValue={insert.role}
                                    onClick={(e: { currentTarget: HTMLSelectElement }) => { handleChangeInsertData("role", e.currentTarget.value) }}
                                >
                                    <option value=""></option>
                                    {roles?.length && roles.map((role: any) => (
                                        <option key={role.key} value={role.key} selected={role.key === insert?.role}>{role.value}</option>
                                    ))}

                                </select>
                            </td>
                            <td>
                                {
                                    (insert.role === "Factory") ?
                                        (
                                            <select
                                                className="form-select w-100"
                                                defaultValue={insert.factoryId ?? ""}
                                                onBlur={(e: { currentTarget: HTMLSelectElement }) => { handleChangeInsertData("factoryId", e.currentTarget.value) }}
                                            >
                                                <option value=""></option>
                                                {factoryList?.length && factoryList.map((p: IFactoryGetAllModel) => (
                                                    <option key={p.id} value={p.id} selected={p.id === update?.factoryId}>{p.name}</option>
                                                ))}
                                            </select>
                                        )
                                        :
                                        (
                                            <p></p>
                                        )
                                }
                            </td>
                            {/* <td hidden={!update.id}>
                                <select
                                    className="form-select-office"
                                    defaultValue={insert.isActive ? "1" : "0"}
                                    onBlur={(e: { currentTarget: HTMLSelectElement }) => { handleChangeInsertData("isActive", e.currentTarget.value === "1") }}
                                >
                                    <option value="1">有効</option>
                                    <option value="0">無効</option>

                                </select>
                            </td> */}
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="goHomeButtonContainer">
                <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton" />
            </div>

            <Modal isActive={showDeleteConfirmModal} onClose={() => handleDeleteCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" onClick={handleDeleteConfirmButtonClick} value="削除" />
                    <input type="button" className="ml-3" onClick={() => { handleDeleteCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>
        </>
    );
};

export default connect((state: RootState) => ({
    userSettingState: state.userSetting
}))(UserList);
